import { collection } from 'firebase/firestore';

import { StepKeys } from '../../../backend/configs/src/configs/steps/steps.types.ts';
import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

// TODO: Change to global block type
export type ReviewIdentifiedBlockConfidence = 'valid' | 'neutral' | 'invalid' | 'link'

export interface ReviewIdentifiedBlock {
    companyId: string;
    entityId: string;
    reportId: string;

    content?: string;
    comment?: string;

    /**
     * Starts from 0
     */
    pageIndex: number
    /**
     * Based on confidence I'll suggest messages for step
     */
    autoConfidence?: ReviewIdentifiedBlockConfidence ,
    manualConfidence?: ReviewIdentifiedBlockConfidence
    /**
     * Can be set instead of pageLabel
     */
    stepKey?: StepKeys
    coordinates: [number, number, number, number],
    blockType: 'text' | 'money' | 'date' | 'unknown' | 'totalValidation' | 'relatedChild'
    rejected?: boolean

    /**
     * Blocks which will be shown when this parent block is active
     */
    relatedBlocks: ReviewIdentifiedBlockRelatedChild[]

    // TODO: Add this fields
    // Blocks which will be shown together with this block active
    // Blocks for linking between reports
    // linkBlocks: string[]

    createdAt: Date
    /**
     * Will be added when generated automatically
     * Can be used as a link for messages match
     */
    label?: string

    /**
     * Only for sum select
     */
    sumSelectMeta?: {
        expectedTotal: number,
        actualTotal: number
    },

    /**
     * Relation to annotation which wil be set only once on initial loading of bloc
     */
    annotaionId?: string
}

export type ReviewIdentifiedBlockRelatedChild = Pick<ReviewIdentifiedBlock, 'coordinates' | 'blockType' | 'pageIndex' | 'content'>

export const REVIEW_IDENTIFIED_BLOCK_COLLECTION = 'reviewIdentifiedBlock'

export const reviewIdentifiedBlockRef = collection(db, 'reviewIdentifiedBlock')

export const {
    useFBItemsQuery: useReviewIdentifiedBlockQuery,
} = getRequestQueries<ReviewIdentifiedBlock, {}>({
    url: REVIEW_IDENTIFIED_BLOCK_COLLECTION,
})
