import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';
import { FbModelBase } from '@/types/modelBase.ts';

import { StepKeys } from '../../../backend/configs/lib/configs/steps/steps.types';
import { db } from '../config.ts';

export interface ReportAnnotation extends FbModelBase {
    page: number;
    stepKey: StepKeys
    format: string
    anotation: string
    annotationId: string
}

export const reportAnnotationRef = collection(db, 'reportAnnotation')

export const {
    useFBItemsQuery: useReportAnnotationQuery,
    useFBItemCreateMutation: useReportAnnotationCreateMutation,
    useFBItemUpdateMutation: useReportAnnotationUpdateMutation,
    fetchFBItemsOnce: fetchReportAnnotationOnce,
} = getRequestQueries<ReportAnnotation, {}>({
    url: 'reportAnnotation',
})
