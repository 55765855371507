import { useEffect } from 'react';

import { trace } from 'firebase/performance';

import { fbPerf } from '@/firestore/config';

// Enum for all possible metrics
export type PerformanceMetrics = 'viewerLoading3' | 'pagePreparation'

// Singleton to store active traces
class TraceManager {
    private static instance: TraceManager;
    private activeTraces: Map<string, ReturnType<typeof trace>>;
    private startTimes: Map<string, number>;
    private lastStageTime: Map<string, number>;

    private constructor() {
        this.activeTraces = new Map();
        this.startTimes = new Map();
        this.lastStageTime = new Map();
    }

    public static getInstance(): TraceManager {
        if (!TraceManager.instance) {
            TraceManager.instance = new TraceManager();
        }
        return TraceManager.instance;
    }

    public setTrace(name: string, traceInstance: ReturnType<typeof trace>) {
        console.log(`[PERF] New trace: ${name}`);
        this.activeTraces.set(name, traceInstance);
    }

    public getTrace(name: string) {
        return this.activeTraces.get(name);
    }

    public removeTrace(name: string) {
        const startTime = this.startTimes.get(name);
        if (startTime) {
            const duration = Date.now() - startTime;
            console.log(`[PERF] ${name}: done in ${duration}ms`);
            this.startTimes.delete(name);
            this.lastStageTime.delete(name);
        }
        this.activeTraces.delete(name);
    }

    public hasTrace(name: string): boolean {
        return this.activeTraces.has(name);
    }

    public markStart(name: string) {
        const now = Date.now();
        this.startTimes.set(name, now);
        this.lastStageTime.set(name, now);
        console.log(`[PERF] ${name}: started`);
    }

    public markStage(traceName: string, stageName: string) {
        const startTime = this.startTimes.get(traceName);
        const lastStageTime = this.lastStageTime.get(traceName);
        if (!startTime || !lastStageTime) return;

        const currentTime = Date.now();
        const totalElapsed = currentTime - startTime;
        const stageDiff = currentTime - lastStageTime;
        
        const trace = this.getTrace(traceName);
        if (trace) {
            trace.putMetric(`stage_${stageName}`, Math.round(totalElapsed / 1000));
            console.log(`[PERF] ${traceName}: ${stageName} @ ${totalElapsed}ms (+${stageDiff}ms)`);
            this.lastStageTime.set(traceName, currentTime);
        }
    }
}

interface UsePerformanceTraceOptions {
    // Custom attributes to add to the trace
    attributes?: Record<string, string>;
    // Whether to start the trace immediately
    startImmediately?: boolean;
    active?: boolean;
}

export const usePerformanceTrace = (traceName: PerformanceMetrics, options: UsePerformanceTraceOptions = {}) => {
    const { attributes = {}, startImmediately = true, active = true } = options;

    const traceManager = TraceManager.getInstance();

    useEffect(() => {
        if(!active) return 

        const existingTrace = traceManager.getTrace(traceName);
        
        // Only create new trace if one doesn't exist and hasn't been stopped
        if (!existingTrace) {
            const newTrace = trace(fbPerf, traceName);

            // Add custom attributes if provided
            Object.entries(attributes).forEach(([key, value]) => {
                newTrace.putAttribute(key, value);
            });

            traceManager.setTrace(traceName, newTrace);

            // Start trace if startImmediately is true and it hasn't been started yet
            if (startImmediately) {
                newTrace.start();
                traceManager.markStart(traceName);
            }
        }
    }, [traceName, JSON.stringify(attributes), startImmediately, active]);

    // Return methods to control the trace
    return {
    // Start the trace manually
        startTrace: () => {
            const existingTrace = traceManager.getTrace(traceName);
            if (existingTrace) {
                existingTrace.start();
                traceManager.markStart(traceName);
            }
        },
        // Stop the trace manually
        stopTrace: () => {
            const existingTrace = traceManager.getTrace(traceName);
            if (existingTrace) {
                existingTrace.stop();
                traceManager.removeTrace(traceName);
            }
        },
        // Add a custom metric
        putMetric: (metricName: PerformanceMetrics, value: number) => 
            traceManager.getTrace(traceName)?.putMetric(metricName, value),
        // Add a custom attribute
        putAttribute: (name: string, value: string) => 
            traceManager.getTrace(traceName)?.putAttribute(name, value),
        // Add a new stage marker
        markStage: (stageName: string) => 
            traceManager.markStage(traceName, stageName),
    };
}; 
