import { FbFilters, FilterCondition, FilterGroup } from '@/firestore/firestore.types';

import { isSingleCondition } from './prepareFbFilters';

// ENV: Helper function to check if condition is a FilterGroup
const isFilterGroup = (condition: FilterCondition | FilterGroup): condition is FilterGroup => {
    return Array.isArray(condition) && (condition[0] === 'and' || condition[0] === 'or');
};

// ENV: Convert single condition to string
const stringifyCondition = (condition: FilterCondition): string => {
    const [field, operator, value] = condition;
    return `${field} ${operator} ${JSON.stringify(value)}`;
};

export const stringifyFbFilters = (filters?: FbFilters): string | undefined => {
    if(!filters) return undefined

    // Handle single condition
    if (isSingleCondition(filters)) {
        return stringifyCondition(filters);
    }

    const [operator, ...conditions] = filters;

    // Convert each condition to string
    const conditionStrings = conditions.map(condition => {
        if (isFilterGroup(condition)) {
            // Wrap nested groups in parentheses
            return `(${stringifyFbFilters(condition)})`;
        }
        return stringifyCondition(condition);
    });

    const result = conditionStrings.join(` ${operator.toUpperCase()} `)

    // Join conditions with operator
    return result
}; 
