import React, { useContext, useEffect, useMemo, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Modal, Select, Space, Tooltip, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Collapse } from 'antd/lib';
import { doc } from 'firebase/firestore';
import { ItemType } from 'rc-collapse/es/interface';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import {
    NOTES_LIBRARY_COMMERCIAL_COMPANY,
    NOTES_LIBRARY_REGULATED_FUNDS, NotesLibraryItem, NotesLibrarySubNote,
} from '@/constants/notesLibrary.ts';
import { ReportReview } from '@/firestore/api/reportReview.ts';
import { db } from '@/firestore/config.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { addAnnotationWithComment } from '@/utils/pdfViewer/addAnnotationWithComment.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';

import { NOTES_LIBRARY_QUERY_CONFIG } from './NotesLibrary.constants.ts';
import { NotesLibraryProps } from './NotesLibrary.types'
import { CUSTOM_MODAL_WIDTH } from '../../constants/modalWidth.ts';

export function firstLetterToUpperCase(str: string): string {
    return (str?.charAt(0).toUpperCase() || '') + (str?.slice(1).toLowerCase() || '');
}

const getNoteTitle = (noteCategory: string, noteType: string) => {
    return firstLetterToUpperCase(noteCategory) + (noteType ? (': ' + firstLetterToUpperCase(noteType)) : '')
}

export const NotesLibrary = (props: NotesLibraryProps) => {
    const [isNotesLibraryOpen, setIsNotesLibraryOpen] = useQueryParam(NOTES_LIBRARY_QUERY_CONFIG.name, NOTES_LIBRARY_QUERY_CONFIG.type)
    const { pdfInstance } = useContext(PdfViewerContext2)
    const authData = useContext<AuthData>(AuthDataContext)

    const { annotationManager } = useViewerDocument()
    const { id: docId } = useParams()
    const [reportSnapshot, isReportSnapshotLoading ] = useDocument<ReportReview>(doc(db, 'reportReviews', docId))
   
    const [categoryOptions, setCategoryOptions] = useState([])
    const [selectedNoteContent, setSelectedNoteContent] = useState(null)
    const [selectedSectionItem, setSelectedSectionItem] = useState<NotesLibraryItem>(null)
    // is this equal selectedNoteContent?
    const [selectedCategoryItem, setSelectedCategoryItem] = useState<NotesLibrarySubNote>(null)
    const [titleInputValue, setTitleInputValue] = useState('')
    const [noteInputValue, setNoteInputValue] = useState('')

    const notesLIbraryConfig = useMemo(() => isReportSnapshotLoading ? [] :
        reportSnapshot?.data()?.reportType === 'commercialCompany' ? NOTES_LIBRARY_COMMERCIAL_COMPANY :
            reportSnapshot?.data()?.reportType === 'regulatedFund' ? NOTES_LIBRARY_REGULATED_FUNDS :
                [], [reportSnapshot?.data()?.reportType, isReportSnapshotLoading])

    const collapseItems = useMemo(() => {
        const res: ItemType[] = []

        if(!isNotesLibraryOpen || !selectedNoteContent) return res
        
        if(selectedCategoryItem.guidanceOrCommentary) {
            res.push({
                key: 'guidanceOrCommentary',
                label: 'Guidance or Commentary',
                children: selectedCategoryItem.guidanceOrCommentary,
            })
        }
        
        if(selectedCategoryItem.additionalPotentialDisclosure) {
            res.push({
                key: 'additionalPotentialDisclosure',
                label: 'Additional potential disclosure',
                children: selectedCategoryItem.additionalPotentialDisclosure,
            })
        }

        return res
    }, [selectedCategoryItem, isNotesLibraryOpen])
    
    const createAnnotations = useCreateAnnotations()

    useEffect(() => {
        () => {
            setCategoryOptions([])
            setSelectedNoteContent(null)
        }
    }, []);

    const handleAddNote = async () => {
        if(!annotationManager || !pdfInstance || !createAnnotations) return

        const noteTitle = getNoteTitle(selectedSectionItem.title, selectedNoteContent.title)
        const noteWording = `
Title
${noteTitle}

Wording
${selectedNoteContent.noteWording}
`
        await addAnnotationWithComment(
            pdfInstance, 
            annotationManager, 
            authData.user.displayName,
            noteWording,
            undefined,
            undefined,
            createAnnotations,
        )
        
        setIsNotesLibraryOpen(false)
    }

    const handleNoteSectionChange = (value: string) => {
        const selectedItem = notesLIbraryConfig.find((noteCategory) => noteCategory.title === value)

        setSelectedSectionItem(selectedItem)
        
        // setSelectedNoteContent(null)
    }

    const handleNoteCategoryChange = (value: string) => {
        const selectedItem = selectedSectionItem?.subNotes.find((note) => note.noteWording === value)

        setSelectedCategoryItem(selectedItem)
    }

    useEffect(() => {
        setSelectedNoteContent(selectedCategoryItem)
    }, [selectedCategoryItem]);

    const noteSectionsOptions = useMemo(() => notesLIbraryConfig.map((noteCategory) => ({
        label: firstLetterToUpperCase(noteCategory.title),
        value: noteCategory.title,
    })), [notesLIbraryConfig])

    useEffect(() => {
        if(selectedSectionItem) return

        setSelectedSectionItem(notesLIbraryConfig[0])
    }, [noteSectionsOptions]);

    useEffect(() => {
        if(!selectedSectionItem || !selectedCategoryItem) return

        setNoteInputValue(selectedCategoryItem.noteWording)
        setTitleInputValue(getNoteTitle(selectedSectionItem.title, selectedCategoryItem.title))
    }, [selectedCategoryItem, selectedSectionItem]);

    useEffect(() => {
        if(!selectedSectionItem) return
        
        const categoryOpt = selectedSectionItem?.subNotes?.map((type) => ({
            label: getNoteTitle(selectedSectionItem?.title, type.title),
            value: type.noteWording,
        }))

        if(!categoryOpt) return

        if(categoryOpt.length) {
            setCategoryOptions(categoryOpt)
            setSelectedCategoryItem( selectedSectionItem?.subNotes[0])
        } else {
            setCategoryOptions([])
            setSelectedCategoryItem(null)
        }
    }, [selectedSectionItem ]);

    const handleTitleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitleInputValue(e.target.value)
    }

    const handleNoteInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNoteInputValue(e.target.value)
    }

    if(!isNotesLibraryOpen) return null

    return (
        <Modal
            open
            confirmLoading={false}
            title={(
                <Flex gap={8} align='baseline'>
                    <Typography.Title level={4}>
                    Notes Library
                    </Typography.Title>
                    <Tooltip title='The Note Library feature offers a comprehensive collection of financial statement note templates and detailed guidance on applying financial reporting standards'>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                    </Tooltip>
                </Flex>
            )}
            centered
            onOk={handleAddNote}
            onCancel={() => setIsNotesLibraryOpen(false)}
            width={CUSTOM_MODAL_WIDTH.medium}
            okButtonProps={{
                disabled: !selectedNoteContent,
            }}
            okText='Add Note'
            styles={{
                body: {
                    overflowY: 'auto',
                },
                content: {
                    overflowY: 'hidden',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Flex vertical gap={8}>
                <Space>
                    <Select
                        defaultActiveFirstOption
                        placeholder='Select note section'
                        options={noteSectionsOptions}
                        title='Note Section'
                        onChange={handleNoteSectionChange}
                        style={{ minWidth: 300 }}
                        value={selectedSectionItem?.title}
                    />
                    {Boolean(categoryOptions.length > 1 && selectedCategoryItem) && (
                        <Select
                            style={{ minWidth: 300 }}
                            defaultActiveFirstOption
                            placeholder='Select note category'
                            options={categoryOptions}
                            title='Note Category'
                            onChange={handleNoteCategoryChange}
                            value={selectedCategoryItem.noteWording}
                        />
                    )}
                </Space>

                {Boolean(selectedCategoryItem) && (
                    <>
                        <Flex gap={4} align='baseline'>
                            <Typography.Title level={5}>
                            Note Template
                            </Typography.Title>
                            <Tooltip title='Here is a note that will be created based on selected parameters'>
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                            </Tooltip>
                        </Flex>

                        <TextArea
                            onChange={handleTitleInputChange}
                            autoSize
                            value={titleInputValue}
                        />
                        <TextArea autoSize value={noteInputValue} onChange={handleNoteInputChange}/>

                        {Boolean(collapseItems.length > 0) && (
                            <Flex style={{ paddingTop: 16 }} vertical>
                                <Collapse items={collapseItems}/>
                            </Flex>
                        )}
                    </>
                )}
            </Flex>
        </Modal>
    )
}
