import React, { useState } from 'react'

import { useAsyncEffect } from 'ahooks';
import { Flex, Spin, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { useQueryParam, BooleanParam } from 'use-query-params'

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';

import { InputFile, InputFileType, TAB_ORDER, useInputFilesQuery } from '../../firestore/api/inputFiles'

export const fileTypeLabels: Record<InputFileType, string | null> = {
    currentYearFinancialStatement: 'Current Year',
    previousYearFinancialStatement: 'Prior Year',
    registrationDocument: 'RCS',
    legalDocument: 'Legal Document',
    other: '',
}
        
export const FileTabs = () => {
    const [files, setFiles] = useState<InputFile[]>([])
    const [loading, setLoading] = useState(true)
    const { id: reportId } = useParams()
    const [activeTab, setActiveTab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type)
    const [tabLoadingParam] = useQueryParam('tabLoading',BooleanParam);

    const inputFilesQuery = useInputFilesQuery({
        filters: [
            'reportId', '==', reportId,
        ],
    });
        
    useAsyncEffect(async () => {
        if (!reportId) return

        try {
            const sortedDocs = [...(inputFilesQuery.data || [])].sort((a, b) => {
                const orderA = TAB_ORDER[a.fileType] || Number.MAX_VALUE;
                const orderB = TAB_ORDER[b.fileType] || Number.MAX_VALUE;
                return orderA - orderB;
            });

            setFiles(sortedDocs)

            if ( sortedDocs.length && !activeTab ) {
                setActiveTab(sortedDocs[0].id)
            }
        } catch (error) {
            console.error('Error fetching files:', error)
        } finally {
            setLoading(false)
        }
    }, [reportId, inputFilesQuery.data])

    const handleTabChange = (newActiveKey: string) => {
        setActiveTab(newActiveKey)
    }

    const getTabLabel = (file: InputFile) => {
        return fileTypeLabels[file.fileType] || file.fileName
    }

    if (loading || !files.length) {
        return null
    }

    return (
        <Flex
            style={{
                // background: 'white',
                // padding: '0 16px',
            }}
        >
            <Tabs
                type='card'
                tabBarStyle={{
                    margin: 0,
                }}
                style={{
                    boxSizing: 'border-box',
                    width: '100%',
                    padding: ' 4px 16px 0 16px',
                    background: 'white',
                    borderTop: 'unset',
                }}
                defaultActiveKey={activeTab || undefined}
                activeKey={activeTab || undefined}
                onChange={handleTabChange}
                items={files.map((file, index) => ({
                    label: <Flex align='center' justify='center' gap={6}>{getTabLabel(file)}  {(file.id === activeTab && tabLoadingParam) && <Spin size='small'/>}</Flex> ,
                    key: file.id,
                }))}
            />
        </Flex>
    )
}
