import React, { Fragment } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Card, Typography, Button, Flex } from 'antd';
import numeral from 'numeral';

import { COLORS } from '@/constants/colors.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey';
import { ValueValidation } from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import styles from '@/pages/FilesPage/FilesPage.module.css'
import { calculateMaxDecimals } from '@/widgets/SumSelect/SumSelect.utils.ts';

import styles from './MathValidation.module.scss';
import {
    MathValidation as IMathValidation,
    useMathValidationUpdateMutation,
} from '../../../../../firestore/api/mathValidation';

const { Text } = Typography;

interface MathValidationProps {
    validationSnap: IMathValidation;
    allComponents: ValueValidation[];
}

export const MathValidation = ({ validationSnap, allComponents }: MathValidationProps) => {
    const { annotationManager } = useViewerDocument()

    const mathValidationUpdateMutation = useMathValidationUpdateMutation()

    if (!validationSnap) {
        return null;
    }
    
    if(!allComponents?.length) {
        return null
    }

    const focusAnnotation = () => {
        const annotations = annotationManager?.getAnnotationsList()

        const annotation = annotations?.find((annot) => annot.getCustomData(CustomDataKey.relatedSnapshotId) === validationSnap.extractedValueId)

        if(annotation) {
            annotationManager?.jumpToAnnotation(annotation)
        }
    }

    const componentSnaps = validationSnap?.components?.map(comp => {
        const compSnap = allComponents.find((doc) => doc.id === comp);
        return compSnap
    }) || [];
    
    const validComponents = componentSnaps
        .filter(Boolean)
        .filter((compSnap) => {
            const compData = compSnap;
            return !isNaN(compData?.normalizedValue) && compData?.normalizedValue !== null;
        })

    const originalValues = validComponents?.map(compSnap => {
        return compSnap?.originalValue?.toString() || '';
    }) || [];
    
    const maxDecimals = calculateMaxDecimals(originalValues);
    const formatNum = (num: number) => numeral(num).format(`0,0.${'0'.repeat(maxDecimals)}`);
    
    return (
        <Card
            className={`${styles.card} ${styles.hoverableCard}`}
            size='small'
            styles={{ body: {
                marginBottom: 16, display: 'flex', gap: '6px', flexWrap: 'wrap', alignItems: 'center', 
                position: 'relative',
                // For close icon
                paddingRight: 16,
            } }}
        >
            <Flex
                className={styles.closeButton}
                style={{
                    zIndex: 10,
                    position: 'absolute',
                    right: 4,
                    top: 4,
                    background: 'white',
                    borderRadius: 4,
                    border: 'solid 2px white',
                }}
            >
                <Button
                    size='small'
                    loading={mathValidationUpdateMutation.isPending}
                    onClick={() => {
                        mathValidationUpdateMutation.mutate({
                            id: validationSnap.id,
                            data: {
                                deletedAt: new Date(),
                            },
                        })
                    }}
                    type='text'
                    icon={<CloseOutlined/>}
                />
            </Flex>
            {validComponents?.map((componentSnap, index) => {
                const compData = componentSnap;

                return (
                    <Fragment key={componentSnap.id}>
                        <Button
                            type='default'
                            style={{ padding: '0 6px' }}
                            onClick={focusAnnotation}
                        >
                            {formatNum(compData?.normalizedValue)}
                        </Button>
                        {index < validComponents.length - 1 && (
                            <Text strong style={{ }}>+</Text>
                        )}
                    </Fragment>
                )
            })}
            <Text strong style={{ }}>=</Text>
            <Button
                onClick={focusAnnotation}
                style={{ 
                    padding: '0 6px',
                    backgroundColor: validationSnap?.valid ? COLORS.green : COLORS.red,
                    borderColor: validationSnap?.valid ? COLORS.green : COLORS.red,
                    color: '#fff', // White text for better contrast
                }}
            >
                {formatNum(validationSnap?.actualValue)}
            </Button>
        </Card>
    );
}
