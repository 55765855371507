import { collection } from 'firebase/firestore';

import { StepKeys } from '../../../backend/configs/src/configs/steps/steps.types.ts';
import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

/**
 * Review status of one step
 */
export interface ReviewStepStatus {
    companyId: string;
    step: StepKeys
    reportOnReviewId: string
    status: ReportReviewStepStatus
}

export type ReportReviewStepStatus = 'accepted' | 'notApplicable'

export const REVIEW_STEP_STATUS_COLLECTION = 'reviewStepStatus'

export const reviewStepStatusRef = collection(db, REVIEW_STEP_STATUS_COLLECTION)

export const {
    useFBItemsQuery: useReviewStepStatusQuery,
} = getRequestQueries<ReviewStepStatus, {}>({
    url: REVIEW_STEP_STATUS_COLLECTION,
})
