import { DocumentReference } from '@firebase/firestore';
import { collection } from 'firebase/firestore';

import { Company } from './company.ts';
import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export type UserRole =
    'ADMIN' | // All permissions
    'PREPARER' | // Less permissions
    'REVIEWER' // More permissions

export const USER_COLLECTION = 'user'

export interface User {
    companyIds: string[]
    email: string
    displayName: string
    avatarUrl: string
    uid: string
    role: UserRole
}

export const userRef = collection(db,USER_COLLECTION )

export const {
    useFBItemsQuery: useUserQuery,
} = getRequestQueries<User, {}>({
    url: USER_COLLECTION,
})
