import { collection } from 'firebase/firestore';

import { StepKeys } from '../../../backend/configs/src/configs/steps/steps.types.ts';
import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

/**
 * General dynamic content which will be added for each review step
 */
export interface ReviewStepValidation {
    step: StepKeys
    status: 'success'
    reportOnReviewId: string
    explanations: string[]
    companyId: string
}

export const reviewStepValidationRef = collection(db, 'reviewStepValidation')

export const {
    useFBItemsQuery: useReviewStepValidationQuery,
} = getRequestQueries<ReviewStepValidation, {}>({
    url: 'reviewStepValidation',
})
