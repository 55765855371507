import { collection, Timestamp } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { db } from '../config.ts';

export type ReportExtractedValueType =
    'money'
    | 'manual'
    | 'number'
    | 'text'
    | 'pageNumber'
    | 'directorName'
    | 'address'
    | 'companyName'
    | 'TOCNumber'
    | 'TOC'
    | 'TOCAssociatedTitle'
    | 'empty' // Case when we need to show than there is no expected value

export const ReportExtractedVlueTypesToFetch: ReportExtractedValueType[] = [
    'money',
    'manual',
    'text',
    'pageNumber',
    'TOC',
    'empty',
    'directorName',
    'address',
    'companyName',
    'TOCAssociatedTitle',
]

export interface CoordinatesQuad {
    x1: number
    x2: number
    x3: number
    x4: number
    y1: number
    y2: number
    y3: number
    y4: number
}

export const REPORT_EXTRACTED_VALUE_LABLEL_BY_TYPE: Record<ReportExtractedValueType, string> = {
    'money': 'Numerical',
    'manual': 'Manual',
    'number': 'Numerical',
    'text': 'Text',
    'pageNumber': 'Page number',
    'directorName': 'Name',
    'address': 'Address',
    'companyName': 'Company name',
    'TOCNumber': 'TOC Page',
    'TOC': 'Table Of Content',
    'empty': 'Empty',
}

export interface ReportExtractedValues {
    id: string
    companyId: string;
    /**
     * @deprecated
     */
    coords: [number, number, number, number];
    /**
     * Will be set by backend in case of manual extraction
     */
    coordinates?: {
        x0: number,
        y0: number,
        width: number
        height: number
    }
    /**
     * Obj with page as a key for multipage quads
     */
    quads?: Record<number, CoordinatesQuad[]>,
    normalizedValue?: number;
    originalValue: string;
    page: number;
    reportId: string;
    /**
     * @deprecated
     */
    sourceFileName?: string;
    type: ReportExtractedValueType
    rejected?: boolean
    /**
     * @deprecated Used 'steps'
     */
    step: string
    steps: string[]
    createdAt?: Timestamp
    inputFileId?: string

    /**
     * For currencies, percents etc
     */
    symbol?: string
    symbolPosition?: 'before' | 'after'
}

export const REPORT_EXTRACTED_VALUES_COLLECTION = 'reportExtractedValues'

export const reportExtractedValuesRef = collection(db, REPORT_EXTRACTED_VALUES_COLLECTION)

export const {
    useFBItemsQuery: useReportExtractedValuesQuery,
    useFBItemByIdQuery: useReportExtractedValuesByIdQuery,
    useFBItemCreateMutation: useReportExtractedValuesCreateMutation,
    useFBItemUpdateMutation: useReportExtractedValuesUpdateMutation,
} = getRequestQueries<ReportExtractedValues, {}>({
    url: REPORT_EXTRACTED_VALUES_COLLECTION,
})
