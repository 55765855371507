import { Core } from '@pdftron/webviewer';

import Annotation = Core.Annotations.Annotation;

import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import AnnotationManager = Core.AnnotationManager;

import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { InputFileType, useInputFileByIdQuery } from '@/firestore/api/inputFiles.ts';

import { useMemo } from 'react';

interface CreateAnnotationsOptions {
    redraw?: boolean
    inputFileType: InputFileType
}

export type CreateAnnotations = (annotations: Annotation[], options: Partial<CreateAnnotationsOptions>) => void

export const createAnnotations = (annotations: Annotation[], annotManager: AnnotationManager, options: CreateAnnotationsOptions) => {
    // Allow annotations only for CY tab
    if(options.inputFileType !== 'currentYearFinancialStatement') return
    
    annotManager.addAnnotations(annotations);

    if(options?.redraw) {
        for(const annotation of annotations) {
            annotManager.redrawAnnotation(annotation);
        }
    }
}

export const useCreateAnnotations = () => {
    const { annotationManager } = useViewerDocument()
    const [activeTab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type)

    const inputFileQuery = useInputFileByIdQuery(activeTab)
    const inputFileType = inputFileQuery.data?.fileType
    
    const res = useMemo(() => (!inputFileQuery.isLoading && inputFileType && annotationManager)
        ? (annotations: Annotation[], options: Partial<CreateAnnotationsOptions>) => createAnnotations(
            annotations, 
            annotationManager,
            { inputFileType, ...options }, 
        )
        : null
    , [annotationManager, inputFileType, inputFileQuery.isLoading],
    )
    
    return res
}
