import { collection } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export interface CompanyEntity {
    name: string
    /**
     * Parent company
     */
    clientCompanyId: string

    companyId: string
}

export const companyEntityRef = collection(db, 'companyEntity')

export const {
    useFBItemsQuery: useCompanyEntityQuery,
} = getRequestQueries<CompanyEntity, {}>({
    url: 'companyEntity',
})
