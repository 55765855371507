import { collection } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export interface ResolvedComment {
    commentId: string

    status: 'applied' | 'rejected'

    companyId: string
    reportOnReviewId: string
}

export const resolvedCommentRef = collection(db, 'resolvedComment')

export const {
    useFBItemsQuery: useResolvedCommentsQuery,
} = getRequestQueries<ResolvedComment, {}>({
    url: 'resolvedComment',
})
