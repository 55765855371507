import { collection } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export interface ReviewStepCheckStatus {
    companyId: string;
    reportId: string;
    checkKey: string;
    stepKey: string;
    checked: boolean;
}

export const REVIEW_STEP_CHECK_STATUS_COLLECTION = 'reviewStepCheckStatus'

export const reviewStepCheckStatusRef = collection(db, 'reviewStepCheckStatus')

export const {
    useFBItemsQuery: useReviewStepCheckStatusQuery,
} = getRequestQueries<ReviewStepCheckStatus, {}>({
    url: REVIEW_STEP_CHECK_STATUS_COLLECTION,
})
