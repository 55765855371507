import { collection } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export interface ClientCompany {
    name: string
    companyId: string
}

// TODO: Rename to userClientRef
export const clientCompanyRef = collection(db, 'clientCompany')

export const {
    useFBItemsQuery: useClientCompanyQuery,
} = getRequestQueries<ClientCompany, {}>({
    url: 'clientCompany',
})
