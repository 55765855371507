/**
 * Converts a hex color to an rgba color with the specified transparency.
 * @param hexColor The hex color string (e.g., '#52c41a').
 * @param alpha The transparency level (0 to 1), where 0 is fully transparent and 1 is fully opaque.
 * @returns The rgba color string.
 */
export const makeColorTransparent = (hexColor: string, alpha: number): string => {
    if (!/^#([A-Fa-f0-9]{6})$/.test(hexColor)) {
        throw new Error('Invalid hex color format. Expected format: #RRGGBB');
    }

    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}; 