import mixpanel from 'mixpanel-browser'

import { AuthData } from '@/components/containers/AuthContext/AuthContext.tsx';
import { CONFIG } from '@/config.ts';

export const identifyMixPanel = (authData: AuthData) => {
    if(!authData.user || !authData.company || !CONFIG.MIXPANEL_KEY || !CONFIG.IS_PROD) return

    mixpanel.identify(authData.user.uid)

    mixpanel.people.set({
        $name: authData.user.displayName,
        $email: authData.user.email,
        companyId: authData.company.id,
        companyName: authData.company.data()?.name,
        host: window.location.host,
        environment: process.env.NODE_ENV,
    })
}
