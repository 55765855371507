import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AddCommentAnnotationEvent, AddCommentAnnotationRequest, Velt } from '@veltdev/types';

import { CreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getMiddleRightCoordinates } from '@/utils/pdfViewer/getMiddleRightCoordinates.ts';

interface AddCommentForAnnotationParams {
    instance: WebViewerInstance,
    annotationManager: Core.AnnotationManager,
    annotation: Core.Annotations.Annotation,
    commentText: string,
    /**
     * Add without focus or opening notes panel
     */
    silentAdd?: boolean,
}

export const addCommentForAnnotation = async ({ 
    annotationManager, 
    annotation, 
    commentText,
    instance,
    silentAdd = false,
}: AddCommentForAnnotationParams) => {
    if(!silentAdd) {
        annotationManager.deselectAllAnnotations()
        annotationManager.jumpToAnnotation(annotation);
        annotationManager.selectAnnotation(annotation);
    }

    // FIXME: Annotationa added date
    const replyAnnot = annotationManager.createAnnotationReply(annotation, commentText)
    
    annotationManager.trigger('annotationChanged', [[replyAnnot], 'add', { imported: false }]);
    
    if(!silentAdd) {
        instance.UI.openElements(['notesPanel']);
    }
}

export const addAnnotationWithComment = async (
    pdfInstance: WebViewerInstance,
    annotationManager: Core.AnnotationManager,
    userName: string,
    text: string,
    annotationPage?: number,
    elementCoords?: {x: number, y: number},
    createAnnotations?: CreateAnnotations,
    addCommentVelt?: (request: AddCommentAnnotationRequest) => Promise<AddCommentAnnotationEvent | null>,
    veltClient?: Velt,
) => {
    annotationManager.setCurrentUser(userName)

    const elementCoordinates = elementCoords || await getMiddleRightCoordinates(pdfInstance, annotationPage)

    const newAnnotationObj = new pdfInstance.Core.Annotations.StickyAnnotation();

    newAnnotationObj.PageNumber = annotationPage ?? pdfInstance.Core.documentViewer.getCurrentPage();
    newAnnotationObj.X = elementCoordinates.x;
    newAnnotationObj.Y = elementCoordinates.y;

    newAnnotationObj.Author = userName;

    // if(!addCommentVelt) {
    //     console.error('Velt comment is skipped')
    // }

    if(!createAnnotations) {
        debugger
        console.error('createAnnotations is not provided')
        return
    }

    createAnnotations?.([newAnnotationObj], { redraw: true })

    addCommentForAnnotation({
        annotationManager,
        annotation: newAnnotationObj,
        commentText: text,
        instance: pdfInstance,
    })

    // if(addCommentVelt && veltClient) {
    //     try {
    //         const filters = {
    //             location: [
    //                 { id: newAnnotationObj.Id },
    //             ],
    //         }
    //
    //         const commentElement = veltClient.getCommentElement();
    //         commentElement.setCommentSidebarFilters(filters);
    //
    //         const commentAnnotation = {
    //             comments: [
    //                 {
    //                     commentText: 'This is a comment',
    //                     commentHtml: '<p>This is a comment</p>',
    //                 },
    //             ],
    //             location: {
    //                 id: newAnnotationObj.Id,
    //                 locationName: 'Annotation',
    //             },
    //             locations: [{
    //                 id: newAnnotationObj.Id,
    //                 locationName: 'Annotation',
    //             }],
    //         };
    //
    //         const addCommentAnnotationRequest: AddCommentAnnotationRequest = {
    //             annotation: commentAnnotation,
    //             options: {
    //                 filters,
    //                 location: {
    //                     id: newAnnotationObj.Id,
    //                     locationName: 'Annotation',
    //                 },
    //                 locations: [{
    //                     id: newAnnotationObj.Id,
    //                     locationName: 'Annotation',
    //                 }],
    //             },
    //         };
    //
    //         const addCommentAnnotationEventData = await addCommentVelt(addCommentAnnotationRequest);
    //
    //         debugger
    //     } catch (e) {
    //         console.error('Velt comment creation failed', e)
    //     }
    // }
}
