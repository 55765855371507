import { useContext, useEffect, useState } from 'react'

import {
    BarsOutlined, CheckSquareOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import { useVeltClient } from '@veltdev/react';
import { CommentAnnotation } from '@veltdev/types/app/models/data/comment-annotation.data.model';
import { Badge, Flex, Menu } from 'antd';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY } from '@/constants/localStorageKeys.ts';
import { useAnnotationReplyQuery, useAnnotationReplyCreateMutation, useAnnotationReplyUpdateMutation } from '@/firestore/api/annotationReply.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { useLocalStorage } from '@/hooks/useLocalStorage.ts';
import {
    AnnotationContext,
    AnnotationContextHeader,
} from '@/pages/ReviewPage';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';

import { GuidePanelProps } from './GuidePanel.types'
import { Messages } from './Messages';
import { StepGuide } from './Steps';

export const GuidePanel = (props: GuidePanelProps) => {
    const { reviewItemData } = props
    const authData = useContext<AuthData>(AuthDataContext)
    const [focusedSnapIdParam] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    const veltIsOn = useFeatureOn('veltIsOn')

    const [navItems, setNavItems] = useLocalStorage<string[]>(ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY, ['guidedReview']);

    const supportedFileType = reviewItemData?.reportType !== 'other'

    useEffect(() => {
        // Show panel all the time for 'other' reports because there is not tabs
        if(((focusedSnapIdParam) || !supportedFileType) && !navItems.includes('comments')) {
            setNavItems(['element'])
        }
    }, [focusedSnapIdParam]);
    
    const { selectedExtractedValSnapId, selectedAnnotationId } = useFocusedValueId()

    const annotationReplyOnAnnotQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['parentAnnotationId', '==', selectedAnnotationId as string],
        ],
    }, {
        enabled: Boolean(selectedAnnotationId),
    })

    const ananotationReplyOnSnapQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['parentSnapId', '==', selectedExtractedValSnapId as string],
        ],
    }, {
        enabled: Boolean(selectedExtractedValSnapId),
    })

    const reviewInProgress = reviewItemData?.reviewStatus === 'inProgress'

    const [tab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);
    
    const locationId = selectedExtractedValSnapId || selectedAnnotationId

    const { client: veltClient } = useVeltClient()

    const [messagesCount, setMessagesCount] = useState(0)

    const [veltCommentsOnAnnotation, setVeltCommentsOnAnnotation] = useState([])

    const fbReplyes = (annotationReplyOnAnnotQuery.data || []).concat(ananotationReplyOnSnapQuery.data || [])

    // If element which is not supporting validation is active
    useEffect(() => {
        if(selectedAnnotationId && !selectedExtractedValSnapId && !navItems.includes('comments') && navItems.includes('element') && veltIsOn) {
            setNavItems(['comments'])
        }
    }, [selectedExtractedValSnapId, selectedAnnotationId, navItems]);

    useEffect(() => {
        if(!veltClient || !tab || !locationId) return
        const commentElement = veltClient.getCommentElement();

        const subscribtion = commentElement.getAllCommentAnnotations(tab).subscribe((items) => {
            const byLocation = items?.filter(el => el.location?.id === locationId)
            const notResolved = byLocation?.filter(el => el.status?.id !== 'RESOLVED')
            setMessagesCount(notResolved?.length || 0)

            setVeltCommentsOnAnnotation(byLocation)
        });
        
        return () => {
            subscribtion.unsubscribe()
        }
    }, [veltClient, tab, locationId]);

    const createMutation = useAnnotationReplyCreateMutation();
    const updateMutation = useAnnotationReplyUpdateMutation();

    useEffect(() => {
        if(!veltCommentsOnAnnotation?.length || annotationReplyOnAnnotQuery.isLoading || ananotationReplyOnSnapQuery.isLoading || !veltIsOn) return
        // If request data oudated. Wait related parent id
        if(annotationReplyOnAnnotQuery.data?.length && annotationReplyOnAnnotQuery.data[0].parentAnnotationId !== selectedAnnotationId && selectedAnnotationId) {
            return;
        }
        // If request data oudated. Wait related parent id
        if(ananotationReplyOnSnapQuery.data?.length && ananotationReplyOnSnapQuery.data[0].parentSnapId !== selectedExtractedValSnapId && selectedExtractedValSnapId) {
            return;
        }

        /**
         * {
         *     "commentHtml": "ffjjjfff",
         *     "commentId": 119137,
         *     "commentText": "ffjjjfff",
         *     "createdAt": 1736933367850,
         *     "editedAt": "2025-01-15T09:47:28.565Z",
         *     "from": {
         *         "clientGroupId": "uqG635c4V4IgrpnVwSAK",
         *         "clientOrganizationId": "uqG635c4V4IgrpnVwSAK",
         *         "clientUserName": "Aleksei Shugaev",
         *         "color": "#19bcfe",
         *         "contacts": [
         *             {
         *                 "email": "d.rozycki@nexly.tech",
         *                 "name": "Dawid Rozycki",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocI0UlQdVf7ueSH_a_lNnP-8kg0uUCbqtfQaHZeVPD_d=s96-c",
         *                 "userId": "UVQpvQTbf1ZEz2vs77THgK0KRs92",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "alek@nexly.tech",
         *                 "name": "Alek Shu",
         *                 "photoUrl": "https://s.gravatar.com/avatar/d174e3ca1d9ff0895752dd222b1a6a5a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *                 "userId": "auth0|66cb461f5ec9050bef667059",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "d.rozycki@nexly.tech",
         *                 "name": "Dawid R",
         *                 "photoUrl": "https://s.gravatar.com/avatar/f0bfc642d48fe545d459b6acf877f6f0?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fdr.png",
         *                 "userId": "auth0|66cb5824e03b2eff84a7d4e2",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "alek@nexly.tech",
         *                 "name": "Alek Shu",
         *                 "photoUrl": "https://s.gravatar.com/avatar/d174e3ca1d9ff0895752dd222b1a6a5a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *                 "userId": "auth0|66cb5a63e03b2eff84a7d62e",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "alek@nexly.tech",
         *                 "name": "Alek  Shu",
         *                 "photoUrl": "https://s.gravatar.com/avatar/d174e3ca1d9ff0895752dd222b1a6a5a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *                 "userId": "auth0|66cb5f55f4723c4d9378bb71",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "annlink2212@gmail.com",
         *                 "name": "Anna Pirozhok",
         *                 "photoUrl": "https://s.gravatar.com/avatar/32d6ece7fbf809d0117133f39f5ad126?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fap.png",
         *                 "userId": "auth0|66cb6402e03b2eff84a7dbad",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "paulina.stawarska@gmail.com",
         *                 "name": "Paulina Stawarska",
         *                 "userId": "auth0|66cd99105e9f447e41ac0e99",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "yashugaev@gmail.com",
         *                 "name": "Aleksei Shu",
         *                 "photoUrl": "https://s.gravatar.com/avatar/f4550eac8a44bb87735924ccb27a31f8?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *                 "userId": "auth0|66d57b0a85151a0c6b5a9883",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "d.rozycki@nexly.tech",
         *                 "name": "Dawid Rozycki",
         *                 "photoUrl": "https://s.gravatar.com/avatar/f0bfc642d48fe545d459b6acf877f6f0?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fdr.png",
         *                 "userId": "auth0|66e1538a52028792889396e1",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "m.grzeszczuk@nexly.tech",
         *                 "name": "Maciej Grzeszczuk",
         *                 "photoUrl": "https://s.gravatar.com/avatar/987b670e20b9b734dffd9001f9d0133f?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmg.png",
         *                 "userId": "auth0|66e153d69724c68a13dab875",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "alek@nexly.tech",
         *                 "name": "Aleksei Shugaev",
         *                 "photoUrl": "https://s.gravatar.com/avatar/d174e3ca1d9ff0895752dd222b1a6a5a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *                 "userId": "auth0|66e15466c87a044431b36e69",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "dmitry@nexly.app",
         *                 "name": "Dmitry Skory",
         *                 "photoUrl": "https://s.gravatar.com/avatar/0af7c6d0e1bc354512292f08e095f8c1?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fds.png",
         *                 "userId": "auth0|671f4defede2daaa8afdda2c",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "stanislav@nexly.app",
         *                 "name": "Stanislav Kropotin",
         *                 "photoUrl": "https://s.gravatar.com/avatar/83209f4c75e3606fdedb72cacf211426?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsk.png",
         *                 "userId": "auth0|671f4e572b71f93be703a44f",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "alek@nexly.app",
         *                 "name": "Antoxa Bro",
         *                 "photoUrl": "https://s.gravatar.com/avatar/2e4b657e97813700c8c8ce53594ade69?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fab.png",
         *                 "userId": "auth0|67533cb9678c8499eec4f341",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "yashugaev@gmail.com",
         *                 "name": "Aleksey Shugaev",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocKocEyIpY0ZKECU3MSKaek8VKx6ut_o6cSL3BJIIjDzQtDCra76=s96-c",
         *                 "userId": "google-oauth2|107161940188100427090",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "rozyckd@gmail.com",
         *                 "name": "Dawid Rozycki",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocKmgidJGGyp0uWgd3BfgpztUfXw0-xm_cKnoAdR5qo346aubw=s96-c",
         *                 "userId": "google-oauth2|110976957465790050096",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "m.grzeszczuk@nexly.tech",
         *                 "name": "Maciej Grzeszczuk",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocI_vDPvxkGiQipuDxQ5vWjgqKolLCtXpPkYRDPgz6it=s96-c",
         *                 "userId": "kDWdCyvPsjVrfWnRb1eCvCzyRX02",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "rozyckd@gmail.com",
         *                 "name": "Dawid Rozycki",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocKm4RvzfeUQQSDTTIUafrN2uTqNO1l_nYshn2gyxNB6=s96-c",
         *                 "userId": "kr43hqNNRtaEPiD4ZesEjlBPC4x2",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "grzeszczukmaciej@gmail.com",
         *                 "name": "Maciej Grzeszczuk",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocLEBqM9-mqjcWMUbt3ky-wX85wRiHDY3LqX1ugG6PwOoAgPVg=s96-c",
         *                 "userId": "pdaVreECNGdd5ju6orz1MrpZIGb2",
         *                 "visibility": "group"
         *             },
         *             {
         *                 "email": "js8965672@gmail.com",
         *                 "name": "John Smith",
         *                 "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocIbeZTGRxDlCNch_LBGcjksTvgO3v9215LQty_u3DWjhGWoHA=s96-c",
         *                 "userId": "uPGtHaTBEnY4DTwhdNJJ1P6eIND2",
         *                 "visibility": "group"
         *             }
         *         ],
         *         "email": "alek@nexly.tech",
         *         "groupId": "uqG635c4V4IgrpnVwSAK",
         *         "initial": "A",
         *         "isAdmin": false,
         *         "name": "Aleksei Shugaev",
         *         "organizationId": "a27a0994a990c2746fea553d836bc5d1",
         *         "photoUrl": "https://s.gravatar.com/avatar/d174e3ca1d9ff0895752dd222b1a6a5a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fas.png",
         *         "textColor": "#FFFFFF",
         *         "type": "signedIn",
         *         "userId": "auth0|66e15466c87a044431b36e69",
         *         "userSnippylyId": "1660152678148596"
         *     },
         *     "isDraft": false,
         *     "isEdited": true,
         *     "lastUpdated": "2025-01-15T09:48:43.450Z",
         *     "status": "updated",
         *     "type": "text"
         * }
         */

        const flatComments = veltCommentsOnAnnotation.map(el => el.comments).flat() as CommentAnnotation['comments']

        const outdated = flatComments.filter(comment => {
            return fbReplyes.some(el => (el?.veltComment?.commentId === comment.commentId) && (el?.veltComment?.editedAt !== comment.editedAt))
        })

        const notCreated = flatComments.filter(comment => {
            return fbReplyes.every(el => (el?.veltComment?.commentId !== comment.commentId));
        });

        // Update outdated comments
        // outdated.forEach(comment => {
        //     const existingReply = fbReplyes.find(el => el?.veltComment?.commentId === comment.commentId);
        //     if (existingReply) {
        //         updateMutation.mutate({
        //             id: existingReply.id, // Assuming `id` is the identifier for the reply
        //             updatedAt:
        //             deletedAt:
        //             data: {
        //                 ...existingReply,
        //                 veltComment: comment
        //             }
        //         });
        //     }
        // });
        //
        // // Create new comments
        // notCreated.forEach(comment => {
        //     createMutation.mutate({
        //         parentAnnotationId: selectedAnnotationId,
        //         parentSnapId: selectedExtractedValSnapId,
        //         replyAnnotationId: comment.commentId, // Assuming this is the correct field
        //         replyText: comment.commentText, // Assuming this is the correct field
        //         veltComment: comment
        //     });
        // });

    }, [veltCommentsOnAnnotation, annotationReplyOnAnnotQuery.dataUpdatedAt, ananotationReplyOnSnapQuery.dataUpdatedAt, veltIsOn]);
    
    return (
        <Flex
            style={{
                flexGrow: 1,
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            {/* Hide panel button */}
            {/*<Button type='text' icon={<MenuUnfoldOutlined/>} style={{ margin: '4px 0 4px 6px' }}/>*/}
            {/*<Divider style={{ margin: 0 }}/>*/}

            <Menu
                onClick={(item) => {
                    setNavItems([item.key])
                }}
                selectedKeys={navItems}
                mode='horizontal'
                items={
                    [
                        supportedFileType ? ({
                            label: 'Steps',
                            key: 'guidedReview',
                            icon: <BarsOutlined/>,
                        }) : null,
                        // {
                        //     label: 'Inbox',
                        //     key: 'inbox',
                        //     icon: <InboxOutlined/>,
                        // },
                        // Show if nothing selected or extracted value
                        ((reviewInProgress && ((selectedExtractedValSnapId))) ? ({
                            label: 'Validation',
                            key: 'element',
                            icon: <CheckSquareOutlined/>,
                        }) : null),
                        (veltIsOn ? ({
                            label: 'Comments',
                            key: 'comments',
                            icon: <Badge count={messagesCount} size='small'><CommentOutlined/></Badge>,
                        }) : null),
                    ].filter(Boolean)
                }
            />
            <Flex
                style={{ overflowX: 'auto',
                    height: '100%',
                    flexDirection: 'column' }}
            >
                {(navItems.includes('element') || navItems.includes('comments')) && (
                    <AnnotationContextHeader/>
                )}

                {navItems.includes('guidedReview') && (
                    <StepGuide reviewItemData={reviewItemData}/>
                )}
                {(navItems.includes('comments') && veltIsOn) && (
                    <Messages/>
                )}
                {navItems.includes('element') && (
                    <AnnotationContext/>
                )}
            </Flex>
        </Flex>
    )
}
