import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';

import { db } from '../config.ts';

export interface ReviewLinkedObjectsItem {
    content: string;
    coords: [number, number, number,number];
    /**
     * @deprecated
     */
    label: string;
    page: number;
    linksCreated?: boolean;
    extractedValueId?: string;
    /**
     * If user rejected the result
     */
    rejected?: boolean;
}

export interface ReviewLinkedObjects {
    companyId: string;
    /**
     * @deprecated
     */
    label: string;
    reportId: string;
    linksGroup: ReviewLinkedObjectsItem[]
    // Undefined deprecated. Should be migrated to 'ecdfNoteLink'
    type: 'ecdfNoteLink' | 'money' | 'text' | undefined
}

export const REVIEW_LINKED_OBJECTS_COLLECTION = 'reviewLinkedObjects'

export const reviewLinkedObjectsRef = collection(db, REVIEW_LINKED_OBJECTS_COLLECTION)

export const {
    useFBItemsQuery: useReviewLinkedObjectsQuery,
} = getRequestQueries<ReviewLinkedObjects, {}>({
    url: REVIEW_LINKED_OBJECTS_COLLECTION,
})
