import { createRef, useContext, useEffect, useMemo, useState, useRef } from 'react'

import { SendOutlined } from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import { Alert, Avatar, Button, Flex, FloatButton, Form, List, Popover, Skeleton, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { where, query, and } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { AiMessage, AiMessageAnnotation, aiMessagesRef } from '@/firestore/api/aiMessages.ts';
import { useReportReviewByIdQuery } from '@/firestore/api/reportReview.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import { AiChatProps } from './AiChat.types.ts';
import { AI_CHAT_QUERY_CONFIG } from '../../../widgets/MagicButtons/MagicButtons.constants.ts';

// Upgrade it automatically if prompt or model changed
// Modify here: https://platform.openai.com/assistants/asst_6dHVW0jjJh0oCpyBgVI7S3Vn

const ASSISTANT_CONFIG = {
    'id': 'asst_6dHVW0jjJh0oCpyBgVI7S3Vn',
}

export const ASSISTANT_AVATAR = 'https://api.dicebear.com/9.x/bottts/svg?seed=Misty&backgroundType[]&baseColor=1e88e5&eyes=roundFrame01&face=round01,round02&mouth=smile01&sides=round&texture[]&top=antennaCrooked'

// Если сообщение не было взято на обработку, то тригернуть еще раз через пару сек и алерт sentry
export const AiChat = (props: AiChatProps) => {
    const { onClick } = props

    const authData = useContext<AuthData>(AuthDataContext)

    const [isAiChatOpen, setIsAiChatOpen] = useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type)

    const { documentViewer } = useViewerDocument()
    
    const [form] = useForm();
    
    const { id: docId } = useParams()
    
    const reportReviewByIdQuery = useReportReviewByIdQuery(docId)

    const listContainerRef = createRef<HTMLDivElement>()
    
    const getUserMessage = (message: string, assistant: boolean = false, completed = false): AiMessage => {
        return {
            companyId: authData.company.id,
            reportId: docId as string,
            userId: authData.user.uid,
            message,
            // @ts-expect-error
            createdAt: new Date(),
            answeredAt: null,
            assistantId: ASSISTANT_CONFIG.id,
            role: assistant ? 'assistant' : 'user',
            status: completed ? 'completed' : 'messageCreated',
            entityType: reportReviewByIdQuery?.data?.reportType || null,
            finFramework: reportReviewByIdQuery?.data?.finFramework || null,
        }
    }
    
    const [aiMessagesSnapshot, aiMessagesSnapshotLoadiing] = useCollection(query(aiMessagesRef,
        and(
            where('reportId', '==', docId),
            where('userId', '==', authData.user?.uid),
        )))
    
    const aiMessagesSorted = useMemo(() => {
        if(aiMessagesSnapshotLoadiing) return []

        const messages = aiMessagesSnapshot?.docs?.sort((a, b) =>
            a.data().createdAt.toDate().getTime() - b.data().createdAt.toDate().getTime(),
        )?.map((doc) => {
            const data = doc.data()
            return data
        }) || []

        return [getUserMessage('How can I assist you today?', true, true)].concat(messages) as AiMessage[]
    }, [aiMessagesSnapshot, aiMessagesSnapshotLoadiing])

    const lastMessage = aiMessagesSorted[aiMessagesSorted.length - 1]

    const [selectedText, setSelectedText] = useState<string | null>(null)

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    /**
     * Selected text detector
     */
    useEffect(() => {
        if(!documentViewer) return
        
        documentViewer.addEventListener('textSelected',(quads, selectedText, pageNumber) => {
            setSelectedText(selectedText);
        });
    }, [documentViewer]);

    /**
     * Auto scroll to the bottom of the list
     */
    useEffect(() => {
        if(listContainerRef.current) {
            listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight
        }
    }, [aiMessagesSnapshotLoadiing, lastMessage?.message, lastMessage?.role, isAiChatOpen]);
    
    const sendMessage = async (formData) => {
        let message = formData.aiMessage

        if(selectedText?.length) {
            message = `
**Selected Text:**
> ${selectedText}

**Prompt:**\n
${message}
`
        }

        const newMessage = getUserMessage(message)
        
        await addDoc(aiMessagesRef, newMessage)
        
        form.resetFields()
    }

    const annotationsInText = (text: string, annotations: AiMessageAnnotation[]): string => {
        annotations?.forEach(annotation => {
            text = text.replace(annotation.text, ` \`${annotation.file_name}\``)
        })
        
        return text
    }

    const waitingAIToBeTriggererd = lastMessage?.status === 'messageCreated' && lastMessage?.role === 'user'

    const isAnswerGenerationInProgress = (lastMessage?.status === 'generatingAnswer' && lastMessage?.role === 'assistant') || waitingAIToBeTriggererd
    
    const pendingTimeIsOut = ((new Date().getTime() - lastMessage?.createdAt?.toDate?.().getTime?.() || 0) < 60000)
    
    const isFirstLetterPendiing = lastMessage?.status === 'generatingAnswer' && !lastMessage?.message?.length && lastMessage?.role === 'assistant' && pendingTimeIsOut

    useEffect(() => {
        if(!pendingTimeIsOut && isAnswerGenerationInProgress && lastMessage) {
            console.error('Can\'t get AI response in 60 seconds', docId, lastMessage)
        }
    }, [pendingTimeIsOut, isAnswerGenerationInProgress]);

    useEffect(() => {
        if(lastMessage?.status === 'error') {
            console.error('AI error')
        }
    }, [lastMessage?.status]);

    const skeletonActive = isFirstLetterPendiing || waitingAIToBeTriggererd

    const popoverContent = (
        <Flex
            style={{ width: '400px', maxHeight: '85vh' }}
            vertical
            size='large'
            gap={8}
        >
            <Flex
                vertical
                style={{
                    overflow: 'auto',
                }}
                ref={listContainerRef}
            >
                <List
                    loading={aiMessagesSnapshotLoadiing}
                    itemLayout='horizontal'
                    // slice to remove initial message
                    dataSource={aiMessagesSorted}
                    style={{ height: '100%', width: '100%' }}
                    renderItem={((item, index) => {
                        if(skeletonActive && index === aiMessagesSorted.length - 1 && item.status === 'generatingAnswer' && item.role === 'assistant') {
                            // Unfinished message with empty text
                            return null
                        }
                        
                        if(item.status === 'error') {
                            return (
                                <Alert
                                    style={{ marginTop: 8, marginBottom: 8 }}
                                    message='Error'
                                    description='There was an error processing your request'
                                    type='error'
                                    showIcon
                                />
                            )
                        }

                        const avatar = item.role === 'assistant' ? ASSISTANT_AVATAR : authData.user.avatarUrl
                        const messageDirection = item.role === 'assistant' ? 'incoming' : 'outgoing'

                        // FIXME: Return loader if message is waiting
                        return (
                            <List.Item >
                                <List.Item.Meta
                                    avatar={<Avatar src={avatar} size='large'/>}
                                    title={<span>{messageDirection === 'outgoing' ? 'You' : 'Nexly AI'}</span>}
                                    description={(
                                        <Typography>
                                            <Markdown>
                                                {annotationsInText(item.message, item.annotations) as string}
                                            </Markdown>
                                        </Typography>
                                    )}
                                />
                            </List.Item>
                        )
                    })}
                />
                <Skeleton
                    active={skeletonActive}
                    loading={skeletonActive}
                    avatar={{
                        size: 'large',
                    }}
                    paragraph={{ rows: 1 }}
                />
            </Flex>
            <Form
                form={form}
                onFinish={sendMessage}
                size='large'
            >
                <Flex
                    align='flex-end'
                    vertical
                >
                    {Boolean(selectedText?.length) && ( 
                        <Alert
                            style={{ width: '100%', marginBottom: 16 }}
                            message='Selected text will be added to the request'
                            type='info'
                            closable
                            onClose={() => setSelectedText('')}
                        />
                    )}  

                    <Form.Item
                        name='aiMessage'
                        style={{ width: '100%' }}
                        rules={[{
                            required: true,
                        }]}
                    >
                        <TextArea
                            ref={textAreaRef}
                            rows={4}
                            maxLength={200}
                        />
                    </Form.Item>
                    <Form.Item 
                        style={{ marginBottom: 8 }}
                    >
                        <Tooltip title={isAnswerGenerationInProgress ? 'Hold on a moment until the previous request is completed' : null}>
                            <Button
                                htmlType='submit'
                                type='primary'
                                icon={<SendOutlined/>}
                                // BLock only for a min. Just in case of problems.
                                loading={isAnswerGenerationInProgress && pendingTimeIsOut}
                                disabled={!authData.user}
                            >
                        Send
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Flex>
            </Form>
            <Flex
                justify='center'
                style={{ textAlign: 'center' }}
            >
                <Typography.Text type='secondary' style={{ fontSize: 12 }}>
                         Nexly AI can make mistakes. Check important info.
                </Typography.Text>
            </Flex>
        </Flex>
    )

    useEffect(() => {
        if (isAiChatOpen && textAreaRef.current) {
            // Trigger with delay because of opening animation
            setTimeout(() => {
                textAreaRef.current?.focus();
            }, 300)
        }
    }, [isAiChatOpen, textAreaRef.current]);

    return (
        <Popover            
            placement='leftBottom'
            content={popoverContent}
            trigger='click'
            style={{ minWidth: '500px' }}
            open={isAiChatOpen}
            onOpenChange={(open) => {
                onClick?.()
            }}
        >
            <FloatButton
                type={isAiChatOpen ? 'primary' : 'default'}
                icon={(
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20px'
                        height='20px'
                        fill={isAiChatOpen ? 'white' : '#343a48'}
                        viewBox='0 0 512 512'
                    >
                        <path
                            d='M208,512a24.84,24.84,0,0,1-23.34-16l-39.84-103.6a16.06,16.06,0,0,0-9.19-9.19L32,343.34a25,25,0,0,1,0-46.68l103.6-39.84a16.06,16.06,0,0,0,9.19-9.19L184.66,144a25,25,0,0,1,46.68,0l39.84,103.6a16.06,16.06,0,0,0,9.19,9.19l103,39.63A25.49,25.49,0,0,1,400,320.52a24.82,24.82,0,0,1-16,22.82l-103.6,39.84a16.06,16.06,0,0,0-9.19,9.19L231.34,496A24.84,24.84,0,0,1,208,512Zm66.85-254.84h0Z'
                        />
                        <path
                            d='M88,176a14.67,14.67,0,0,1-13.69-9.4L57.45,122.76a7.28,7.28,0,0,0-4.21-4.21L9.4,101.69a14.67,14.67,0,0,1,0-27.38L53.24,57.45a7.31,7.31,0,0,0,4.21-4.21L74.16,9.79A15,15,0,0,1,86.23.11,14.67,14.67,0,0,1,101.69,9.4l16.86,43.84a7.31,7.31,0,0,0,4.21,4.21L166.6,74.31a14.67,14.67,0,0,1,0,27.38l-43.84,16.86a7.28,7.28,0,0,0-4.21,4.21L101.69,166.6A14.67,14.67,0,0,1,88,176Z'
                        />
                        <path
                            d='M400,256a16,16,0,0,1-14.93-10.26l-22.84-59.37a8,8,0,0,0-4.6-4.6l-59.37-22.84a16,16,0,0,1,0-29.86l59.37-22.84a8,8,0,0,0,4.6-4.6L384.9,42.68a16.45,16.45,0,0,1,13.17-10.57,16,16,0,0,1,16.86,10.15l22.84,59.37a8,8,0,0,0,4.6,4.6l59.37,22.84a16,16,0,0,1,0,29.86l-59.37,22.84a8,8,0,0,0-4.6,4.6l-22.84,59.37A16,16,0,0,1,400,256Z'
                        />
                    </svg>
                )}
                onClick={() => {
                    setIsAiChatOpen(!isAiChatOpen)
                }}
                tooltip='Chat with Nexly AI'
            />
        </Popover>
    )
}
