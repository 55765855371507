import { PresetColorType } from 'antd/es/_util/colors';
import { collection } from 'firebase/firestore';

import { getRequestQueries } from '@/api/utils/getRequestQueries';
import { COLORS } from '@/constants/colors.ts';
import { ReportExtractedValueType } from '@/firestore/api/reportExtractedValues.ts';

import { db } from '../config.ts';

export type ValueValidationConfidence =
    'valid' |
    'invalid' |
    /**
     * Verification of automated check without confidence set
     */
    'verificationRequired' |
    'notApplicable'

export type ValueValidationType = 'py' | 'ic' | 'ma' | 'ec' | 'other'

export const VALUE_VALIDATION_COLOR: Record<ValueValidationConfidence, string> = {
    valid: COLORS.green,
    invalid: COLORS.red,
    verificationRequired: COLORS.orange,
    notApplicable: '#a8a8a8',
}

export const TAG_COLOR_BY_CONF_COLOR: Record<ValueValidationConfidence, PresetColorType> = {
    valid: 'green',
    invalid: 'red',
    verificationRequired: 'orange',
    notApplicable: undefined,
}

export const valueValidationTypes: ValueValidationType[] = ['py', 'ic', 'ma', 'ec', 'other']

export const VALIDATION_BY_VALUE_TYPE: Partial<Record<ReportExtractedValueType, ValueValidationType[]>> = {
    text: valueValidationTypes.filter(el => el !== 'ma'),
}

export type ValueValidationObj = Partial<Record<ValueValidationType, ValueValidationConfidence>>

export interface ValueValidation {
    id: string
    companyId: string;
    reportId: string;
    entityId: string;

    /**
     * Related to "reportExtractedValues" collection
     */
    extractedValueId: string;

    manual?: ValueValidationObj
    auto?: ValueValidationObj
}

export const VALUE_VALIDATION_COLLECTION = 'valueValidation'

export const valueValidationRef = collection(db, VALUE_VALIDATION_COLLECTION)

export const {
    useFBItemsQuery: useValueValidationQuery,
    useFBItemByIdQuery: useValueValidationByIdQuery,
    useFBItemCreateMutation: useValueValidationCreateMutation,
    useFBItemUpdateMutation: useValueValidationUpdateMutation,
} = getRequestQueries<ValueValidation, {}>({
    url: VALUE_VALIDATION_COLLECTION,
})
