import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';

export const identifiedBlockColorByConfidence = (pdfInstance: WebViewerInstance): Record<ReviewIdentifiedBlockConfidence, Core.Annotations.Color> => {
    return ({
        // neutral: new pdfInstance.Core.Annotations.Color(0, 0, 0),
        neutral: new pdfInstance.Core.Annotations.Color(255, 205, 69),
        invalid: new pdfInstance.Core.Annotations.Color(255, 77, 79),
        valid: new pdfInstance.Core.Annotations.Color(82, 196, 26),
        link: new pdfInstance.Core.Annotations.Color(45, 85, 255),
    });
}
