import React, { useEffect } from 'react'

import { GatewayOutlined } from '@ant-design/icons';
import { useVeltClient, VeltCommentsSidebar } from '@veltdev/react';
import { Button, Flex, Result } from 'antd';
import { useQueryParam } from 'use-query-params';

import { useReportExtractedValuesByIdQuery } from '@/firestore/api/reportExtractedValues.ts';
import { useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { IDENTIFY_VALUE_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';

import { MessagesProps } from './Messages.types'

export const Messages = (props: MessagesProps) => {
    const { selectedExtractedValSnapId, selectedAnnotationId } = useFocusedValueId()
    const selectedId = selectedExtractedValSnapId || selectedAnnotationId

    const reportExtractedValuesByIdQuery = useReportExtractedValuesByIdQuery(selectedExtractedValSnapId, { enabled: !!selectedExtractedValSnapId })

    const [, setIdentifyValOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)

    const { client: veltClient } = useVeltClient()
    
    useEffect(() => {
        if(!selectedId || !veltClient) return
        
        const locationConf = {
            // TODO: Change 'Annotation' to content of the annotation or something else dynamic
            locationName: reportExtractedValuesByIdQuery.data?.originalValue?.toString() || 'Annotation' || undefined,
            id: selectedId,
        }
        
        veltClient.setLocation(locationConf);

        // Activate filter in Sidebar by account
        const filters = {
            location: [
                { id: selectedId },
            ],
        }
                 
        const commentElement = veltClient.getCommentElement();
        commentElement.setCommentSidebarFilters(filters);

        commentElement.disableCollapsedComments()
    }, [selectedId, veltClient, reportExtractedValuesByIdQuery.dataUpdatedAt]);
    
    return (
        <Flex
            style={{ 
                overflowX: 'auto', width: '100%',
                alignItems: (!selectedExtractedValSnapId) ? 'center' : 'flex-start',
                justifyContent: (!selectedExtractedValSnapId) ? 'center' : 'flex-start', 
                flexGrow: 1,
            }}
        >
            {!selectedId ? (
                <Result
                    icon={<GatewayOutlined/>}
                    title='Select a value to begin commenting'
                    extra={(
                        <Button
                            onClick={() => {
                                setIdentifyValOpen(true)
                            }}
                        >Create a New</Button>
                    )}
                />
            ) : (
                <VeltCommentsSidebar
                    // onCommentClick={(...args) => {
                    //     debugger
                    // }}
                    filterConfig={{
                        location: {
                            enable: true,
                            name: 'Element',
                            enableGrouping: false,
                            multiSelection: false,
                        },
                        priority: {
                            enable: true,
                            enableGrouping: false,
                        },
                    }}
                    shadowDom={false}
                    onCommentClick={async (payload, ...args) => {
                        debugger
                        // const annotationId = payload.location.id;
                        //
                        // const { documentViewer, annotationManager } = PDFInstance.Core;
                        // const annotList = await annotationManager.getAnnotationsList();
                        // const annotations = await annotationManager.getAnnotationById(annotationId);
                        // annotationManager.selectAnnotation(annotList[0]);

                        // 
                        // Ищем аннотацию с указанным ID
                        // const annotation = annotations.find((anno: any) => anno.id === annotationId);

                        // const pageNumber = annotation.pageNumber;
                        // const rect = annotation.rect; // [left, top, right, bottom]

                        // Метод для перевода фокуса на аннотацию
                        // PDFInstance.UI.sc(pageNumber, rect);

                        // Выделить аннотацию, если это возможно
                        // if (PDFInstance.UI.selectAnnotation) {
                        //     PDFInstance.UI.selectAnnotation(annotation.id);
                        // }
                    }}
                    pageMode
                    embedMode
                />
            )}
        </Flex>
    )
}
