import { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

export const useFpsMeter = (isActive: boolean) => {
    const frameCountRef = useRef(0);
    const lastFrameTimeRef = useRef(performance.now());
    const animationFrameIdRef = useRef<number | null>(null);
    const { id: docId } = useParams()
    const errorShownForDocId = useRef<string>()

    useEffect(() => {
        if (isActive) {
            // Record the start time when tracking begins
            lastFrameTimeRef.current = performance.now();

            const updateFPS = () => {
                frameCountRef.current++;
                animationFrameIdRef.current = requestAnimationFrame(updateFPS);
            };

            // Start tracking FPS
            updateFPS();
        } else {
            const now = performance.now();
            const elapsed = now - lastFrameTimeRef.current;
                
            // If not active, log the FPS for the last active period
            if (frameCountRef.current > 1 && elapsed > 500) {
                const averageFPS = (frameCountRef.current / elapsed) * 1000;
                const framesPerSecond = averageFPS.toFixed(2)

                console.log(`[FPS] Scroll avg: ${framesPerSecond}`);
                
                if((averageFPS < 50) && errorShownForDocId.current !== docId) {
                    console.error('[FPS] Low FPS detected for the report id', docId)
                    errorShownForDocId.current = docId
                }
            }
            // Reset counters
            frameCountRef.current = 0;
        }

        // Cleanup function
        return () => {
            if (animationFrameIdRef.current !== null) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
        };
    }, [isActive]);
}
