import { ArrayParam } from 'use-query-params';

/**
 * Param only for IC navigation
 *
 * @deprecated Remove it. It's now based on focused snap id
 */
export const ACTIVE_IC_VALUE_QUERY_PARAM = {
    name: 'amv',
    type: ArrayParam,
}
