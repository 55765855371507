import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
    CalculatorOutlined, CloseOutlined,
    CopyOutlined,
    InsertRowBelowOutlined, PlusCircleOutlined, ReloadOutlined,
} from '@ant-design/icons';
import { Core } from '@pdftron/webviewer';
import { useQueryClient } from '@tanstack/react-query';
import { useAsyncEffect } from 'ahooks';
import { Button, Divider, Flex, FloatButton, Popover, Tooltip, Typography } from 'antd';
import { message } from 'antd/lib';
import Decimal from 'decimal.js';
import {
    doc,
    getDoc,
    addDoc,
    setDoc,
} from 'firebase/firestore';
import numeral from 'numeral'
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { COLORS } from '@/constants/colors.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import {
    fetchMathValidationOnce,
    MATH_VALIDATION_COLLECTION,
    MathValidation,
    mathValidationRef, useMathValidationUpdateMutation,
} from '@/firestore/api/mathValidation.ts';
import {
    ReportExtractedValues, ReportExtractedVlueTypesToFetch,
    useReportExtractedValuesQuery, useReportExtractedValuesUpdateMutation,
} from '@/firestore/api/reportExtractedValues.ts';
import { reportReviewRef } from '@/firestore/api/reportReview';
import { reviewStepCommentRef } from '@/firestore/api/reviewStepComment';
import {
    useValueValidationQuery,
    useValueValidationCreateMutation, useValueValidationUpdateMutation, ValueValidationConfidence,
} from '@/firestore/api/valueValidation.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { CustomToolNames } from '@/widgets/PdfViewer2';
import {
    SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG,
    SUM_SELECT_COMPONENTS_QUERY_PARAM,
    SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG,
} from '@/widgets/SumSelect/SumSelect.contants.ts';

import { SumSelectProps } from './SumSelect.types'
import { calculateMaxDecimals } from './SumSelect.utils.ts';
import { PdfViewerContext2 } from '../../App.tsx';
import { SUM_SELECT_QUERY_PARAM } from '../MagicButtons/MagicButtons.constants.ts';
import { createExtractedValueAnnotation } from '../PdfViewer2/PdfViewer2.utils.ts';

/**
 * TODO: Keep the same order (can be ordered based on annotations)
 */
export const SumSelect = (props: SumSelectProps) => {
    const { onClick } = props

    const { id: reportId } = useParams()

    const page = useCurrentPage()

    const [focusedSnapIdParam] = useQueryParam(QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    const lastSelectedAnnotation = useRef(focusedSnapIdParam)

    const focusedStapAnnotationId = focusedSnapIdParam ?? lastSelectedAnnotation.current

    useEffect(() => {
        if (!focusedSnapIdParam) return
        lastSelectedAnnotation.current = focusedSnapIdParam
    }, [focusedSnapIdParam]);

    const reportExtractedValuesQuery = useReportExtractedValuesQuery({
        filters: [
            'and',
            ['reportId', '==', reportId as string],
            ['type', 'in', [...ReportExtractedVlueTypesToFetch, 'number']],
        ], 
    })
    
    const reportExtractedValuesUpdateMutation = useReportExtractedValuesUpdateMutation()
    const valueValidationUpdateMutation = useValueValidationUpdateMutation()

    const focusedExtractedValue = reportExtractedValuesQuery.data?.find(doc => doc.id === focusedStapAnnotationId)

    const { pdfInstance } = useContext(PdfViewerContext2)

    // Actual
    const [total, setTotal] = useState<Decimal>(() => new Decimal(0))
    // Expected
    const [expectedTotal, setExpectedTotal] = useState<Decimal>(() => new Decimal(0))
    const [sumSelectMultyCompMode, setSumSelectMultyCompMode] = useQueryParam<boolean>(SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG.name, SUM_SELECT_MULTY_COMPONENT_QUERY_CONFIG.type)
    const [sumSelectCompareToSelectedMode, setSumSelectCompareToSelectedMode] = useQueryParam<boolean>(SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.name, SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.type)
    const [open, setOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const [, setActiveMoneyValues] = useQueryParam(SUM_SELECT_COMPONENTS_QUERY_PARAM.name, SUM_SELECT_COMPONENTS_QUERY_PARAM.type)

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [decimals, setDecimals] = useState<number>(2)
    const [componentAnnotations, setComponentAnnotations] = useState<Core.Annotations.Annotation[]>([])
    const [totalAnnotation, setTotalAnnotation] = useState<Core.Annotations.Annotation>()
    const [isExpectedValid, setIsExpectedValid] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)

    const [selectedMoneyValues, setSelectedMoneyValues] = useState<ReportExtractedValues[]>([])

    // All comonents (now only last selected)
    const [allSelectedMoneyValues, setAllSelectedMoneyValues] = useState<ReportExtractedValues[]>([])

    const { annotationManager, documentViewer } = useViewerDocument()

    const createAnnotations = useCreateAnnotations()
    
    /**
     * Create number values annotations (by default they are hidden)
     */
    useAsyncEffect(async () => {
        if (!annotationManager || !createAnnotations) return;

        const annotationsList = annotationManager.getAnnotationsList()
        const numberValues = allSelectedMoneyValues
            // Filter only number values
            .filter(val => val.type === 'number')
            // exclude already created
            .filter(val => !annotationsList.some(annotation => 
                annotation.getCustomData(CustomDataKey.snapshotRerenderKey) === val.Id,
            ))

        for (const numberValue of numberValues) {
            createExtractedValueAnnotation({
                pdfInstance,
                moneyValue: numberValue,
                annotationsList,
                annotationManager,
                createAnnotations,
            })
        }
    }, [allSelectedMoneyValues, annotationManager, createAnnotations]);

    const authData = useContext<AuthData>(AuthDataContext)
    
    const formatNum = (num: number) => numeral(num).format(`0,0.${'0'.repeat(decimals)}`)

    const handleAreaSelected = useCallback(async (annotaion: Core.Annotations.Annotation) => {
        const annotationRect = annotaion.getRect()

        // To avoid accidentally included values into the list
        const coordsCorrectionVal = 3

        annotationRect.x1 += coordsCorrectionVal
        annotationRect.y1 += coordsCorrectionVal
        annotationRect.x2 -= coordsCorrectionVal
        annotationRect.y2 -= coordsCorrectionVal

        const page = annotaion.getPageNumber()

        // Get all annotations on the current page
        const selectedNumbers = reportExtractedValuesQuery.data?.filter(data => {
            if (data.page !== page - 1) return false

            const rect = data.coordinates
            if (!rect) return false

            const isIntersecting = (
                rect.x0 < annotationRect.x2 &&
                (rect.x0 + rect.width) > annotationRect.x1 &&
                rect.y0 < annotationRect.y2 &&
                (rect.y0 + rect.height) > annotationRect.y1
            )

            return isIntersecting
        })

        const moneyValues = selectedNumbers
            // Filter only money values
            .filter(doc => doc.type === 'money')

        const selectedNumberValues = selectedNumbers
            // Filter only number values
            .filter(doc => doc.type === 'number')
            // remove same values from money and number based on coords
            .filter((doc) => {
                const { coordinates: numberRect } = doc

                // Find intersection with possible deviations
                return !moneyValues.some((moneyDoc) => {
                    const { coordinates: moneyRect } = moneyDoc

                    const possibleCoordsDiff = 3

                    const isIntersecting = (
                        Math.abs(moneyRect.x0 - numberRect.x0) < possibleCoordsDiff &&
                        Math.abs(moneyRect.y0 - numberRect.y0) < possibleCoordsDiff &&
                        Math.abs(moneyRect.width - numberRect.width) < possibleCoordsDiff &&
                        Math.abs(moneyRect.height - numberRect.height) < possibleCoordsDiff
                    )

                    return isIntersecting
                })
            })

        setSelectedMoneyValues([...moneyValues, ...selectedNumberValues])
    }, [pdfInstance, reportExtractedValuesQuery.data, reportExtractedValuesQuery.isLoading]);

    useEffect(() => {
        if (pdfInstance && annotationManager) {
            const areaSelectedCallback = (annotations: Core.Annotations.Annotation[], action, { imported }) => {
                // Ignore auto added itemss
                if (imported) {
                    return;
                }
                
                const annotation = annotations[0];

                if (action === 'add' && annotation?.ToolName === CustomToolNames.SumSelect) {
                    handleAreaSelected(annotation)
                }
            }

            if (open) {
                annotationManager.addEventListener('annotationChanged', areaSelectedCallback);
            } else {
                annotationManager.removeEventListener('annotationChanged', areaSelectedCallback);

                // Delete created annotations
                const toDelete = [...componentAnnotations, totalAnnotation].filter(Boolean)
                annotationManager.deleteAnnotations(toDelete)
                setTotal(new Decimal(0))
            }
        }
    }, [pdfInstance, open, annotationManager]);

    useEffect(() => {
        setSumSelectMultyCompMode(false)
        setSumSelectCompareToSelectedMode(false)

        setOpen(false)
    }, []);

    useEffect(() => {
        if (!open) {
            setActiveMoneyValues([])
            setSelectedMoneyValues([])
            setAllSelectedMoneyValues([])
        }
    }, [open]);

    const handleClick = () => {

        // setOpen(!open)
        onClick()
    }

    const totalVal = Number(total.toDecimalPlaces(2).toString())
    const expectedVal = Number(expectedTotal.toDecimalPlaces(2).toString())

    useAsyncEffect( async () => {
        if (!documentViewer) return

        if (!selectedMoneyValues.length) return

        // Exclude already added
        const filteredSelectedMoneyValues = selectedMoneyValues
            .filter(doc => doc.normalizedValue !== undefined)
            // exclude selected if ADD mode
            .filter(doc => {
                return !(allSelectedMoneyValues.some(el => el.id === doc.id) && sumSelectMultyCompMode)
            })
            
        if(!filteredSelectedMoneyValues.length) return

        const numbers = filteredSelectedMoneyValues.map(doc => doc.normalizedValue)
        const originalValues = filteredSelectedMoneyValues.map(doc => doc.originalValue)

        // Calculate maxDecimals from the normalized values
        const stringNumbers = originalValues.map(num => num.toString())
        const maxDecimals = calculateMaxDecimals(stringNumbers)
        setDecimals(maxDecimals)

        let uptatedTotal = undefined

        const filterByCoordinates = (extractedValues: ReportExtractedValues[]) => extractedValues.sort((a, b) => {
            // First sort by page
            if (a.page !== b.page) {
                return a.page - b.page;
            }

            const coordsA = a.coordinates;
            const coordsB = b.coordinates;

            if (!coordsA || !coordsB) return 0;

            // Define vertical sections/rows with a threshold
            const verticalThreshold = 10; // pixels
            const isInSameRow = Math.abs(coordsA.y0 - coordsB.y0) <= verticalThreshold;

            if (isInSameRow) {
                // If in the same row, sort left to right
                return coordsA.x0 - coordsB.x0;
            } else {
                // If in different rows, sort top to bottom
                // Note: PDF coordinates start from bottom, so larger Y means higher position
                return coordsA.y0 - coordsB.y0
            }
        });

        if (!sumSelectMultyCompMode) {
            setAllSelectedMoneyValues(filterByCoordinates(filteredSelectedMoneyValues))

            uptatedTotal = numbers.reduce((acc, el) => acc.plus(el), new Decimal(0))
            setTotal(uptatedTotal)
        } else {
            setAllSelectedMoneyValues(filterByCoordinates([...allSelectedMoneyValues, ...filteredSelectedMoneyValues]))

            uptatedTotal = numbers.reduce((acc, el) => acc.plus(el), total)
            setTotal(uptatedTotal)
        }

        // Scroll to the bottom
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight
        }
    }, [selectedMoneyValues, documentViewer]);

    // Sync with url params
    useEffect(() => {
        const ids = allSelectedMoneyValues.map(el => el.id)
        setActiveMoneyValues(ids)
    }, [allSelectedMoneyValues]);
    
    const handleReset = () => {
        setTotalAnnotation(undefined)
        setComponentAnnotations([])
        setActiveMoneyValues([])
        setAllSelectedMoneyValues([])
        setTotal(new Decimal(0))
    }

    const queryClient = useQueryClient()
    
    // Total setup
    useEffect(() => {
        const expValue = focusedExtractedValue?.normalizedValue
        if(!expValue || (focusedExtractedValue?.type !== 'money' && focusedExtractedValue?.type !== 'number')) return;
        const actualValue = totalVal

        if (actualValue === undefined || actualValue === null) {
            // message.error('Could not calculate the actual value')
            return
        } else if (expValue === undefined || expValue === null) {
            // message.error('Could not find the expected value')
            return;
        }
        
        setExpectedTotal(new Decimal(expValue))
        
        // Compare against sum of components (expected)
        const isValid = actualValue === expValue;

        setIsExpectedValid(isValid)
    }, [focusedExtractedValue?.normalizedValue, total]);

    // Get existing value validation or create new
    const { data: valueValidation, isLoading: valueValidationLoading } = useValueValidationQuery({
        filters: [
            'and',
            ['reportId', '==', reportId as string],
            ['extractedValueId', '==', focusedExtractedValue?.id as string],
        ],
    }, {
        enabled: !!reportId && !!focusedExtractedValue?.id,
    })
        
    const valueValidationCreateMutation = useValueValidationCreateMutation()
    const mathValidationUpdateMutation = useMathValidationUpdateMutation()

    const handleSumSelectSave = async () => {
        setSaving(true)

        if(valueValidationLoading) return

        if (!allSelectedMoneyValues.length || !reportId) return;

        if (!focusedExtractedValue) {
            console.error('Can\'t find focusedExtractedValue')
            return
        }

        // Get report data
        const reportDoc = doc(reportReviewRef, reportId);
        const reportSnap = await getDoc(reportDoc);
        const reportData = reportSnap.data();

        if (!reportData) {
            console.error('No report data found');
            return;
        }

        // Create math validation record
        const mathValidationDoc = doc(mathValidationRef);
        const mathValidationData: MathValidation = {
            companyId: authData.company.id,
            reportId: reportId,
            entityId: reportData.entityId,
            extractedValueId: focusedExtractedValue.id,
            components: allSelectedMoneyValues.map(v => v.id),
            expectedValue: expectedVal,
            actualValue: totalVal,
            valid: isExpectedValid,
            type: 'sum',
            source: 'user',
        };

        // Save math validation
        await setDoc(mathValidationDoc, mathValidationData);

        queryClient.invalidateQueries({ queryKey: [MATH_VALIDATION_COLLECTION] })
        
        const mathValidationsSnap = await fetchMathValidationOnce(
            {
                filters: [
                    'and',
                    ['reportId', '==', reportId],
                    ['extractedValueId', '==', focusedExtractedValue.id],
                ],
            },
        );

        // REject previous math validations
        const mathValidationRejectPromises = mathValidationsSnap.map(el => {
            if(el.id === mathValidationDoc.id) return Promise.resolve();

            const promise = mathValidationUpdateMutation.mutateAsync({
                id: el.id,
                data: {
                    deletedAt: new Date(),
                },
            })

            return promise
        })

        await Promise.all(mathValidationRejectPromises)
        
        const finalValidationStatus: ValueValidationConfidence =
            (isExpectedValid) ? 'valid' : 'invalid';
        
        let valueValidationData;
        if (!valueValidation?.length) {
            // Create new validation
            valueValidationData = {
                companyId: authData.company.id,
                reportId: reportId,
                entityId: reportData.entityId,
                extractedValueId: focusedExtractedValue.id,
                manual: {
                    ma: finalValidationStatus ,
                },
            };
            
            await valueValidationCreateMutation.mutateAsync({ 
                data: valueValidationData,
            })
        } else {
            // Exclude id
            const {
                id,
                ...existingDoc
            } = valueValidation[0]

            valueValidationData = {
                ...existingDoc,
                manual: {
                    ...existingDoc.manual,
                    ma: finalValidationStatus,
                },
            };

            await valueValidationUpdateMutation.mutateAsync({
                id,
                data: valueValidationData,
            });
        }

        // Create review comment if validation failed
        if (!isExpectedValid) {
            const errorMessage = `There's a discrepancy in the total. The total for the selection is <b>${formatNum(totalVal)}</b>, while the total in the table shows <b>${formatNum(expectedVal)}</b>.`;

            const messageItem = {
                step: reportData.currentStep || null,
                suggestedMessage: errorMessage,
                explanation: errorMessage,
                pageNumber: page - 1,
                reportOnReviewId: reportSnap.id,
                companyId: reportData.companyId,
                validationType: 'ma',
                extractedValueId: focusedExtractedValue.id,
            };

            await addDoc(reviewStepCommentRef, messageItem);
        }

        // If value was accepted - convert it to money type
        const docsUpdatePromises = selectedMoneyValues
            .filter(doc => doc.type === 'number')
            .map(doc => {
                return reportExtractedValuesUpdateMutation.mutateAsync({
                    id: doc.id,
                    data: {
                        type: 'money',
                    },
                })
            })

        await Promise.all(docsUpdatePromises);
        
        handleReset()
        setSaving(false)
    };

    const handleRemoveComponent = (value: ReportExtractedValues) => {
        const updatedList = allSelectedMoneyValues.filter(el => el.id !== value.id)
        const updatedIds = updatedList.map(el => el.id)

        setAllSelectedMoneyValues(updatedList)
        setActiveMoneyValues(updatedIds)
    }

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const totalComponentsEl = (
        <>
            <Flex
                style={{ maxHeight: 300,
                    overflowX: 'auto',
                    justifyContent: 'center' }}
            >
                <Flex
                    justify='center'
                    align='center'
                    vertical
                    style={{ height:
                            'fit-content',
                    alignItems: 'flex-end',
                    position: 'relative' }}
                    ref={scrollContainerRef}
                >
                    {
                        allSelectedMoneyValues.map((el, index) => (
                            <Flex
                                key={index}
                                align='center'
                                justify='flex-end'
                                gap={4}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                style={{ position: 'relative' }}
                            >
                                {/* Spaces to keep cross visible and hoverable */}
                                <Typography.Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {formatNum(el.normalizedValue as number)}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography.Text>
                                {hoveredIndex === index && (
                                    <Button
                                        type='text'
                                        size='small'
                                        icon={<CloseOutlined style={{ color: COLORS.gray }}/>}
                                        onClick={() => handleRemoveComponent(el)}
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            padding: 0,
                                            height: 'auto',
                                        }}
                                    />
                                )}
                            </Flex>
                        ))
                    }
                </Flex>
                
            </Flex>
            <Flex
                style={{ borderTop: 'solid 1px rgba(16, 24, 40, 0.88)',
                    marginTop: 8,
                    paddingTop: 8 }}
                gap={8}
                vertical
            >
                <Typography.Title
                    level={5}
                    style={{ margin: 0,
                        textAlign: 'center' }}
                >
                    {formatNum(totalVal)}
                </Typography.Title>
                {sumSelectCompareToSelectedMode ? (
                    <Typography.Title
                        level={5}
                        style={{
                            marginTop: 0,
                            textAlign: 'center',
                            color: isExpectedValid ? COLORS.green : COLORS.red,
                        }}
                    >
                        Exp: {!focusedExtractedValue ? '—' : formatNum(expectedVal)}
                    </Typography.Title>
                ) : null}

            </Flex>
        </>
    )

    // Update the popoverContent to include MathValidation component
    const popoverContent = (
        <>
            <Divider style={{ margin: '12px 0px' }}/>
            <Flex
                justify='center'
                align='center'
                vertical
            >
                {allSelectedMoneyValues.length > 0 ? <Typography.Text>{totalComponentsEl}</Typography.Text> :
                    <Typography.Text type='secondary'>No numbers found</Typography.Text>}
            </Flex>
            
            <Divider style={{ margin: '12px 0px' }}/>
            <Flex align='flex-end' justify='space-between'>
                <Flex gap={8}>
                    <Tooltip title='Enable multi-component validation'>
                        <Button
                            type={sumSelectMultyCompMode ? 'primary' : 'default'}
                            icon={<PlusCircleOutlined/>}
                            onClick={() => {
                                !sumSelectMultyCompMode ? setSumSelectMultyCompMode(true) : setSumSelectMultyCompMode(false)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title='Validate against the selected value'>
                        <Button
                            type={sumSelectCompareToSelectedMode ? 'primary' : 'default'}
                            icon={<InsertRowBelowOutlined/>}
                            onClick={() => {
                                !sumSelectCompareToSelectedMode ? setSumSelectCompareToSelectedMode(true) : setSumSelectCompareToSelectedMode(false)
                            }}
                        />
                    </Tooltip>
                </Flex>
                <Flex gap={8}>
                    <Button
                        disabled={allSelectedMoneyValues.length === 0}
                        type='text'
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            const totalComponents = allSelectedMoneyValues.map(el => el.normalizedValue as number)
                            const totalComponets = [...totalComponents, total].map(el => formatNum(el)).join('\n')
                            navigator.clipboard.writeText(totalComponets)
                            message.success('Copied to clipboard')
                        }}
                    />
                    {(sumSelectCompareToSelectedMode && Boolean(focusedExtractedValue)) ? (
                        <Button
                            type='primary'
                            onClick={handleSumSelectSave}
                            disabled={!allSelectedMoneyValues.length}
                            loading={saving}
                        >
                            Save
                        </Button>
                    ) : null}
                </Flex>
            </Flex>
        </>
    )

    return (
        <Popover
            placement='rightTop'
            title={(
                <Flex justify='center' align='center' style={{ position: 'relative' }}>
                    <Flex justify='center'>Sum Select ({allSelectedMoneyValues.length})</Flex>
                    <Tooltip title='Reset values'>
                        <Button
                            style={{
                                position: 'absolute',
                                right: 0,
                            }}
                            type='text'
                            icon={<ReloadOutlined/>}
                            onClick={handleReset}
                        />
                    </Tooltip>
                </Flex>
            )}
            content={popoverContent}
            trigger='click'
            open={open}
            overlayStyle={{ minWidth: '280px',
                maxHeight: 400 }}
            overlayInnerStyle={{ padding: 4 }}
        >
            <FloatButton
                type={open ? 'primary' : 'default'}
                icon={<CalculatorOutlined/>}
                onClick={handleClick}
                tooltip='Sum Select'
            />
        </Popover>
    )
}
