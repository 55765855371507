import { BooleanParam, NumberParam, StringParam, withDefault } from 'use-query-params';

export const QUERY_PARAMS_CONFIG = {
    PAGE: {
        key: 'page',
        type: NumberParam,
    },
    TAB: {
        key: 'tab',
        type: StringParam,
    },
    ANNOTATIONS_READY_FOR_SUMMARY: {
        key: 'ars',
        type: withDefault(BooleanParam, true),
    },
    FOCUSED_ANNOTATION_QUERY_PARAM: {
        key: 'focusedAnnotation',
        type: StringParam,
    },
    FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM: {
        key: 'extr_snap_id',
        type: StringParam,
    },
};
