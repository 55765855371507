import { QueryFieldFilterConstraint } from '@firebase/firestore';
import { and, or, Query, query, where } from 'firebase/firestore';

import { FbFilters, FilterCondition, FilterGroup } from '@/firestore/firestore.types';

// ENV: Helper function to check if condition is a FilterGroup
const isFilterGroup = (condition: FilterCondition | FilterGroup): condition is FilterGroup => {
    return Array.isArray(condition) && (condition[0] === 'and' || condition[0] === 'or');
};

// ENV: Helper function to check if input is a single condition
export const isSingleCondition = (filters: FbFilters): filters is FilterCondition => {
    return Array.isArray(filters) && filters.length === 3 && typeof filters[0] === 'string' && !isFilterGroup(filters);
};

export const prepareFbFilters = (baseQuery: Query, filters: FbFilters): Query | Query[] => {
    const getConditions = (filters: FbFilters): QueryFieldFilterConstraint => {
    // Handle single condition case
        if (isSingleCondition(filters)) {
            return where(filters[0], filters[1], filters[2]);
        }

        const [operator, ...conditions] = filters;

        if (operator === 'and') {
            const andItems = conditions.reduce<QueryFieldFilterConstraint[]>((acc, condition) => {
                if (isFilterGroup(condition)) {
                    const nestedQuery = getConditions(condition);
                    acc.push(nestedQuery);
                } else {
                    acc.push(where(condition[0], condition[1], condition[2]));
                }
                return acc;
            }, []);

            return and(...andItems);
        } else if (operator === 'or') {
            const whereConditions = conditions.reduce<QueryFieldFilterConstraint[]>((acc, condition) => {
                if (isFilterGroup(condition)) {
                    const nestedQuery = getConditions(condition);
                    acc.push(nestedQuery);
                } else {
                    acc.push(where(condition[0], condition[1], condition[2]));
                }
                return acc;
            }, []);

            return or(...whereConditions)
        }

        throw new Error('Invalid operator: must be "and" or "or"');
    
    }
    
    return query(baseQuery, getConditions(filters));
};
