import { useEffect, useRef } from 'react';

/**
 * Hook to measure the duration a boolean flag remains true.
 * 
 * @param {string} label - The label to identify the boolean flag.
 * @param {boolean} flag - The boolean flag to track.
 * @param {number} threshold - The duration threshold in seconds to trigger an error.
 */
export const useBoolTime = (label: string, flag: boolean, threshold?: number) => {
    const startTimeRef = useRef<number | null>(null);

    useEffect(() => {
        if (flag) {
            // Start timing when the flag becomes true
            startTimeRef.current = performance.now();
        } else if (startTimeRef.current !== null) {
            // Calculate and log the duration when the flag becomes false
            const endTime = performance.now();
            const duration = ((endTime - startTimeRef.current) / 1000).toFixed(2);
            console.log(`[B.PERF]: ${label}: ${duration}s`);
            
            // Check if the duration exceeds the threshold
            if (threshold && parseFloat(duration) > threshold) {
                console.error(`[B.PERF]: ${label} exceeded threshold: ${duration}s`);
            }
            
            startTimeRef.current = null;
        }
    }, [flag, label, threshold]);
}; 