import { useEffect } from 'react';

import * as Sentry from '@sentry/react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import { randomString } from '@/utils/randomString.ts';

const SENTRY_URL = import.meta.env.VITE_SENTRY_ENDPOINT
const ENV = import.meta.env.VITE_ENVIRONMENT

// TODO: Fix to real version
const release = randomString()

/**
 * @todo: Record 100% of sessions for PROD companies and skip testing companies
 */
export const initSentry = () => {
    if (SENTRY_URL) {
        Sentry.init({
            // Track all requests to the API (connect to back sentry)
            tracePropagationTargets: [
                /^https:\/\/(?:.*\.)?nexly\.(app|tech)\/api\/config/,
            ],
            ignoreErrors: [
                /@firebase\/firestore: Firestore/, // https://nexly.sentry.io/issues/5986982/events/561ffb98d2844db1b4d1ddb7e312b3f3/
                /@firebase\/analytics: Failed to fetch/, // https://nexly.sentry.io/issues/6792358/events/525f04f1ffa64412a171817a6838331c/
            ],
            denyUrls: [ 
                // Patterns for ignoring specific errors by url
            ],
            dsn: SENTRY_URL,
            environment: ENV,
            integrations: [
                Sentry.browserProfilingIntegration(),
                Sentry.browserTracingIntegration(),
                Sentry.captureConsoleIntegration({
                    levels: ['error', 'warn'],
                }),
                Sentry.extraErrorDataIntegration(),
                Sentry.httpClientIntegration({
                    failedRequestTargets: [
                        'http://localhost',
                        '/api/.*/',
                        'https://dev.nexly.tech',
                        'https://nexly.tech'
                    ],
                }),
                Sentry.replayIntegration({
                    unmask: ['*'],
                }),
                Sentry.reportingObserverIntegration(),
                Sentry.sessionTimingIntegration(),
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                // Sentry.feedbackIntegration({
                //     colorScheme: 'light',
                //     showBranding: false,
                //     showEmail: false,
                //     showName: false,
                //     useSentryUser: {
                //         name: 'name',
                //         email: 'email',
                //     },
                //     submitButtonLabel: 'Send Feedback',
                //     triggerLabel: '',
                // }),
            ],
            release: release,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: (ENV === 'production') ? 1 : (ENV === 'development') ? 0.5 : 0,
            replaysOnErrorSampleRate: 1.0,
        })

        console.info('Sentry initialized')
    } else {
        console.error('Sentry is NOT initialized')
    }

    window.addEventListener('visibilitychange', () => {
        const isTabOpen = document.visibilityState === 'visible'

        Sentry.withScope(function (scope) {
            scope.setTag('isTabOpen', isTabOpen)
        })
    })
}
