import { useContext, useEffect, useRef, useState } from 'react';

import { Core } from '@pdftron/webviewer';
import { useAsyncEffect, useDebounceFn } from 'ahooks';
import { doc, query, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import {
    AnnotationReply,
    useAnnotationReplyCreateMutation, useAnnotationReplyQuery,
    useAnnotationReplyUpdateMutation,
} from '@/firestore/api/annotationReply.ts';
import { useReportAnnotationQuery } from '@/firestore/api/reportAnnotation.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { AnnotationChangeSource } from '@/types/pdfLib/annotationChangeSource.ts';
import { ACTIVE_IC_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { AnnotationVariant, getAnnotationConfigByVariant } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { SUM_SELECT_COMPONENTS_QUERY_PARAM } from '@/widgets/SumSelect/SumSelect.contants.ts';

import Annotation = Core.Annotations.Annotation;

import { ReportTableObjects, reportTableObjectsRef } from '@/firestore/api/reportTableObjects.ts';
import { createAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { useTableOfContentQuery } from '@/firestore/api/tableOfContent';
import { AnnotationUpdateType } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';
import { lastSelectedAnnotationId, lastSelectedSnapId, useFocusedValueId } from '@/hooks/useFocusedValueId.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';

import Annotations = Core.Annotations;

import { useReportExtractedValuesQuery } from '@/firestore/api/reportExtractedValues.ts';

import StickyAnnotation = Core.Annotations.StickyAnnotation;

export const useApplyAnnotations = () => {
    const { id: docId } = useParams()

    const { annotationManager } = useViewerDocument()

    const [reportSnapshot, reportItemLoading] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const authData = useContext<AuthData>(AuthDataContext)
    
    const reportAnnotationQuery = useReportAnnotationQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['companyId', '==', authData.company.id],
        ],
    })

    const reportData = reportSnapshot?.data()

    const deleteAnnotForOtherSteps = (activeStep: string) => {
        const annotations = annotationManager.getAnnotationsList()

        // Delete for other steps
        const toDelete = annotations
            .filter((annotation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'
                const confidence = annotation.getCustomData('tickConfidence') as ReviewIdentifiedBlockConfidence
                const excluFromSymmaryFlag = annotation.getCustomData('excludeFromSummary') === 'true'
                const excludeFromSummary = confidence === 'neutral' || confidence === 'valid' || confidence === 'link' || excluFromSymmaryFlag

                if (stepFinished) {
                    return excludeFromSummary
                } else {
                    const step = annotation.getCustomData('relatedStep')

                    return step?.length && (step !== activeStep)
                }
            })

        const namesToDelete = toDelete.map((annotation) => annotation.getCustomData('toolName'))

        annotationManager?.deleteAnnotations(toDelete, {
            source: AnnotationChangeSource.temporaryDelete,
        })
    }

    const createNotAppliedAnnotations = async (activeStep: string) => {
        const annotations = annotationManager?.getAnnotationsList() || []
        const annotationsApplied = annotations.map((annotation) => annotation.Id)

        const regexToolName = new RegExp(`${CustomDataKey.toolName}:([a-zA-Z_]+)`);

        const annotWIthoutStep = reportAnnotationQuery.data
            ?.filter((annotaion) => {
                return !annotaion.stepKey;
            }) || []

        const annotationsNames = annotWIthoutStep.map((annotation) => annotation.anotation.match(regexToolName)?.[1])

        const annotForCurrentStep = reportAnnotationQuery.data
            ?.filter((annotaion) => {
                return activeStep && (annotaion.stepKey === activeStep);
            }) || []

        const annotationsForSummaryReport = reportAnnotationQuery.data
            ?.filter((reportAnnotatation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'

                if (!stepFinished) return false

                const regexConf = new RegExp(`${CustomDataKey.tickConfidence}:([a-zA-Z_]+)`);

                const normString = reportAnnotatation.anotation.replace(/&quot;/g, '')

                const confMatch = normString.match(regexConf);
                const nameMatch = normString.match(regexToolName);

                const confidence = confMatch?.[1] as ReviewIdentifiedBlockConfidence | undefined

                if (!confidence) return true

                const res = (confidence === 'invalid') ? true : false

                return res
            }) || []

        const toCreate =
            [...annotWIthoutStep, ...annotForCurrentStep, ...annotationsForSummaryReport]
                .filter((annotationData) => {
                    return !annotationsApplied.includes(annotationData.annotationId);
                })

        for (const annotationData of toCreate) {
            const annotations = await annotationManager?.importAnnotations(annotationData.anotation);
            annotations.forEach(a => {
                annotationManager?.redrawAnnotation(a);
            });
        }
    }

    /**
     * Re-apply annotations every step change
     */
    useEffect(() => {
        (async () => {
            if (!annotationManager || reportItemLoading || !reportData) return

            const activeStep = reportData?.currentStep

            deleteAnnotForOtherSteps(activeStep)
            await createNotAppliedAnnotations(activeStep)
        })()
    }, [reportData?.currentStep, annotationManager, reportItemLoading, reportData?.reviewStatus]);
}

/**
 * Will be triggered when block loaded fisrt time
 */
export const useCreateAnnotationsForIdentifiedBlocks = () => {

}

/**
 * Modified anntations based on query params
 * Sepearted from main render logic to keep better performance
 */
export const useAnnotStylesMofier = () => {
    const [sumComponentsParam] = useQueryParam(SUM_SELECT_COMPONENTS_QUERY_PARAM.name, SUM_SELECT_COMPONENTS_QUERY_PARAM.type)
    const [activeMoneyValues] = useQueryParam(ACTIVE_IC_VALUE_QUERY_PARAM.name, ACTIVE_IC_VALUE_QUERY_PARAM.type)
    const { selectedExtractedValSnapId, selectedAnnotationId } = useFocusedValueId()

    const { annotationManager, pdfInstance } = useViewerDocument()
    const page = useCurrentPage()

    /**
     * Styling for SumSelect components
     */
    useEffect(() => {
        if (!annotationManager) return
        const annotList = annotationManager.getAnnotationsList()
        const annotListByPage = annotList.filter((annot) => annot.PageNumber === page)

        annotListByPage.forEach((annot) => {
            const snapshotId = annot.getCustomData(CustomDataKey.relatedSnapshotId)
            const annotationVariant = annot.getCustomData(CustomDataKey.annotationVariant)

            if (sumComponentsParam?.includes(snapshotId)) {

                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].Opacity

                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValueActive)

                annotationManager.redrawAnnotation(annot)
            } else if (annotationVariant === AnnotationVariant.moneyValueActive) {
                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].Opacity

                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)

                annotationManager.redrawAnnotation(annot)
            }
        })
    }, [sumComponentsParam, annotationManager, page]);

    const prevActiveAnnotations = useRef<Annotation[]>([])

    /**
     * Styling for focused IC value
     */
    useAsyncEffect(async () => {
        if (!annotationManager) return

        const annotList = annotationManager.getAnnotationsList()

        const defaultStyle = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue]
        const activeStyle = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.IcNavigatorActive]
        
        // Rollback unselecred annotations
        if (prevActiveAnnotations.current.length) {
            const intersectedWithSnapId = prevActiveAnnotations.current?.some((annotation) => {
                const relatedSnap = annotation.getCustomData(CustomDataKey.relatedSnapshotId)
                return relatedSnap === selectedExtractedValSnapId
            })
            
            if(!intersectedWithSnapId) {
                prevActiveAnnotations.current.forEach((annot) => {
                    annot.setBorderStyle(defaultStyle.BorderStyle)
                    annot.StrokeColor = defaultStyle.StrokeColor
                    annot.Opacity = defaultStyle.Opacity
                    annot.StrokeThickness = defaultStyle.StrokeThickness
                    annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)
                    annotationManager.redrawAnnotation(annot)
                })
            } else {
                debugger
            }
        }

        const activeAnnotations = annotList.filter((annot) => {
            const snapshotId = annot.getCustomData(CustomDataKey.relatedSnapshotId)
            return activeMoneyValues?.includes(snapshotId)
        })

        const activeSnapIds = activeAnnotations.map(el => {
            return el.getCustomData(CustomDataKey.relatedSnapshotId)
        })

        const isIntersectingWithSnapId = activeSnapIds.includes(selectedExtractedValSnapId)

        if(!isIntersectingWithSnapId) {
            // Select updated list of items
            activeAnnotations.forEach((annot) => {
                annot.setBorderStyle(activeStyle.BorderStyle)
                annot.StrokeColor = activeStyle.StrokeColor
                annot.Opacity = activeStyle.Opacity
                annot.StrokeThickness = activeStyle.StrokeThickness
                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.IcNavigatorActive)
                annotationManager.redrawAnnotation(annot)
            })
            
            prevActiveAnnotations.current = activeAnnotations
        }
    }, [
        page,
        activeMoneyValues,
        annotationManager,
        selectedExtractedValSnapId,
    ]);
    
    const prevModifiedMessage = useRef<Annotation>(null)
    // Hack which increase the cahnce for annot to be selected
    const [retryTrigger, setRetryTrigger] = useState(0)

    const isVeltOn = useFeatureOn('veltIsOn')

    /**
     * Styling for focused Message element
     */
    useEffect(() => {
        if (!annotationManager || !pdfInstance || !isVeltOn) return
        
        const selectedAnnot = selectedAnnotationId ? annotationManager.getAnnotationById(selectedAnnotationId) as StickyAnnotation : undefined
        const isMessage = selectedAnnot?.elementName === 'text' && selectedAnnot.Icon === 'Comment'

        if(selectedAnnot && isMessage) {
            // Rollback unselecred annotations
            if (prevModifiedMessage.current) {
                (prevModifiedMessage.current as StickyAnnotation).StrokeColor = new pdfInstance.Core.Annotations.Color(255,205,69)
                annotationManager.redrawAnnotation(prevModifiedMessage.current)
            }

            const newColor = new pdfInstance.Core.Annotations.Color(45, 89, 116, 1)

            selectedAnnot.StrokeColor = newColor

            annotationManager.redrawAnnotation(selectedAnnot)
            
            prevModifiedMessage.current = selectedAnnot

            setRetryTrigger(0)
        }

        if(!selectedAnnot && retryTrigger < 3) {
            setTimeout(() => {
                setRetryTrigger(retryTrigger + 1)
            }, 200)
        }
    }, [
        selectedAnnotationId,
        retryTrigger,
        page,
        annotationManager,
        pdfInstance,
    ]);
    
    const prefPrevFocusedBySnapId = useRef([])
    
    /**
     * Focused snap id highlight
     */
    useEffect(() => {
        if(!annotationManager) return
        
        const focusedAnnotationObj = getAnnotationBySnapId(annotationManager, selectedExtractedValSnapId)

        if(prefPrevFocusedBySnapId.current.length) {
            prefPrevFocusedBySnapId.current.forEach(annot => {
                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].Opacity
                annot.StrokeColor = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].StrokeColor
                annot.StrokeThickness = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].StrokeThickness
                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)

                annotationManager.redrawAnnotation(annot)
            })
            prefPrevFocusedBySnapId.current = []
        }
        if(selectedExtractedValSnapId) {
            if(focusedAnnotationObj) {
                focusedAnnotationObj.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].BorderStyle)
                focusedAnnotationObj.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].Opacity
                focusedAnnotationObj.StrokeColor = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].StrokeColor
                focusedAnnotationObj.StrokeThickness = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].StrokeThickness
                focusedAnnotationObj.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValueActive)

                annotationManager.redrawAnnotation(focusedAnnotationObj)

                prefPrevFocusedBySnapId.current.push(focusedAnnotationObj)
            }
        }
    }, [selectedExtractedValSnapId, annotationManager, selectedExtractedValSnapId, page]);
}

/**
 * Apply tables extraction result
 */
export const useTableDebug = () => {
    const { id } = useParams()

    const tableDebugOn = useFeatureOn('tableDebug')

    const { annotationManager, pdfInstance } = useViewerDocument()

    const [tablesSnap] = useCollection<ReportTableObjects, ReportTableObjects>(query(
        reportTableObjectsRef,
        where(
            'reportId',
            '==',
            id,
        ),
    ))

    const createAnnotations = useCreateAnnotations()

    useAsyncEffect(async () => {
        if (!tableDebugOn || !createAnnotations) return

        try {
            const tableSnaps = tablesSnap?.docs

            if (!tableSnaps?.length || !annotationManager) return

            const annotationsList = annotationManager.getAnnotationsList()

            for (const tableSnap of tableSnaps) {
                const tableData = tableSnap.data()
                const { boundingRegions, rowConfigs } = tableData

                const annotation = await createAnnotation({
                    createAnnotations,
                    doNotCreate: false,
                    annotationsList,
                    annotationManager,
                    pdfInstance,
                    pageIndex: boundingRegions[0].page ,
                    coordinates: [boundingRegions?.[0].x, boundingRegions?.[0].y, boundingRegions[0].width, boundingRegions[0].height],
                    relatedSnapshotId: tableSnap.id,
                    annotationVariant: AnnotationVariant.tableBorder,
                    reply: `Table ID: ${tableSnap.id}`,
                    excludeFromSummary: true,
                    skipEventHandlers: true,
                })

                const totalRows = rowConfigs?.filter((row) => row.total) || []

                if (totalRows.length) {
                    for (const row of totalRows) {
                        const rowIndex = rowConfigs?.findIndex((r) => r.separator === row.separator)
                        const prevTableRow = rowConfigs[rowIndex - 1]

                        const height = prevTableRow?.separator ? (row.separator - prevTableRow.separator) : (boundingRegions[0].y + boundingRegions[0].height - row.separator)
                        const coords = [boundingRegions[0].x, row.separator - height, boundingRegions[0].width, height]

                        const annotationRow = await createAnnotation({
                            createAnnotations,
                            doNotCreate: false,
                            annotationsList,
                            annotationManager,
                            pdfInstance,
                            pageIndex: boundingRegions[0].page,
                            coordinates: coords,
                            showInNotesPanel: false,
                            relatedSnapshotId: tableSnap.id + '_row_' + row.separator,
                            annotationVariant: AnnotationVariant.totalRow,
                            reply: 'Separator',
                            excludeFromSummary: true,
                            skipEventHandlers: true,
                        })
                    }
                }
            }
        } catch (e) {
            console.error(e)
        }
    }, [tablesSnap, annotationManager, tableDebugOn, createAnnotations]);
}

const groupAndSortReplies = (replies: AnnotationReply[], excludePrents: string[], excludeReplies: string[]): Record<string, AnnotationReply[]> => {
    return replies.reduce<Record<string, AnnotationReply[]>>((acc, reply) => {
        const parentId = reply.parentAnnotationId || reply.parentSnapId;

        if(excludePrents.includes(parentId as string )) return acc
        if(excludeReplies.includes(reply.id)) return acc

        if (!parentId) return acc;

        if (!acc[parentId]) {
            acc[parentId] = [];
        }
        
        acc[parentId].push(reply);

        // Sort the replies for this parent by createdAt
        acc[parentId].sort((a, b) => {
            const aTime = a.createdAt?.toDate?.().getTime() || 0;
            const bTime = b.createdAt?.toDate?.().getTime() || 0;
            return aTime - bTime;
        });

        return acc;
    }, {});
};

/**
 * Apply table of content debug visualization
 */
export const useTableOfContentDebug = () => {
    const { id } = useParams()

    const tocDebugOn = useFeatureOn('tocDebug')

    const { annotationManager, pdfInstance } = useViewerDocument()

    const tableOfContentQuery = useTableOfContentQuery({
        filters: [
            'reportId', '==', id as string,
        ],
    }, {
        enabled: tocDebugOn,
    })

    const createAnnotations = useCreateAnnotations()

    useAsyncEffect(async () => {
        if (!tocDebugOn || !createAnnotations || tableOfContentQuery.isLoading) return

        try {
            const tocItems = tableOfContentQuery.data || []

            if (!tocItems?.length || !annotationManager) return

            const annotationsList = annotationManager.getAnnotationsList()
            let newAnnotations: Annotation[] = []

            for (const tocItem of tocItems) {
                const tocData = tocItem
                const { coordinates, pageIndex } = tocData

                const annotation = await createAnnotation({
                    createAnnotations,
                    doNotCreate: true,
                    annotationsList,
                    annotationManager,
                    pdfInstance,
                    pageIndex: pageIndex,
                    coordinates: [coordinates.x0, coordinates.y0, coordinates.width, coordinates.height],
                    showInNotesPanel: false,
                    relatedSnapshotId: tocItem.id,
                    annotationVariant: AnnotationVariant.tocBorder,
                    reply: `TOC Level ${tocData.level}. Content: ${tocData.content}`,
                    excludeFromSummary: true,
                    skipEventHandlers: true,
                })

                if (annotation) {
                    newAnnotations.push(annotation)
                }
            }

            const filteredAnnotations = newAnnotations.filter(Boolean)

            createAnnotations(filteredAnnotations)
            newAnnotations = []
        } catch (e) {
            console.error(e)
        }
    }, [tableOfContentQuery.dataUpdatedAt, annotationManager, tocDebugOn, createAnnotations, tableOfContentQuery.isLoading]);
}

export const useReplyAnnotationHandler = () => {
    const { annotationManager, pdfInstance } = useViewerDocument()
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()
    const [tab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);
    const { selectedExtractedValSnapId, selectedAnnotationId } = useFocusedValueId()
    const page = useCurrentPage()

    const annotationReplyUpdateMutation = useAnnotationReplyUpdateMutation()
    const annotationReplyCreateMutation = useAnnotationReplyCreateMutation()

    const parrentsApplied = useRef<string[]>([])

    const reportAnnotationQuery = useReportAnnotationQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['page', '==', page as number - 1],
        ],
    })
    const reportExtractedValuesQuery = useReportExtractedValuesQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['page', '==', page as number - 1],
        ],
    })

    const annotParentIds = reportAnnotationQuery.data?.map(e => e.annotationId) || []
    
    const annotationReplyOnAnnotQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['inputFileId', '==', tab as string],
        ],
    }, {
        enabled: !reportAnnotationQuery.isLoading,
    })

    const snapParentIds = reportExtractedValuesQuery.data?.map(e => e.id) || []
    
    const ananotationReplyOnSnapQuery = useAnnotationReplyQuery({
        filters: [
            'and',
            ['reportId', '==', docId as string],
            ['inputFileId', '==', tab as string],
        ],
    }, {
        enabled: !reportExtractedValuesQuery.isLoading,
    })

    const ananotationReplyQueryLoading = annotationReplyOnAnnotQuery.isLoading || ananotationReplyOnSnapQuery.isLoading
    const ananotationReplyQueryData =
        (annotationReplyOnAnnotQuery.data || [])
            .concat(ananotationReplyOnSnapQuery.data || [])
            // Keep only annotations for this page elements
            .filter((e) => snapParentIds.includes(e.parentSnapId as string) || annotParentIds.includes(e.parentAnnotationId as string))
            // Remove duplicates based on unique 'id'
            // In some reason I have them here
            .reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    acc.push(current);
                }
                return acc;
            }, []);

    console.log('Reply: annotationReplyOnAnnotQuery.data', annotationReplyOnAnnotQuery.data)

    const ananotationReplyQueryLastUpdatedAt = annotationReplyOnAnnotQuery.dataUpdatedAt > ananotationReplyOnSnapQuery.dataUpdatedAt ? annotationReplyOnAnnotQuery.dataUpdatedAt : ananotationReplyOnSnapQuery.dataUpdatedAt

    const [annotationsUpdatedTrigger, setAnnotationsUpdatedTrigger] = useState(0)

    const { run: debouncedSetAnnotationsUpdatedTrigger } = useDebounceFn(
        () => setAnnotationsUpdatedTrigger(prev => prev + 1),
        // Just to avoid too much calls when id apply all annotations for summary pdf
        { wait: 500 },
    );

    const deletedIds = useRef<string[]>([])
    const createdAnnotations = useRef<string[]>([])
    const addedFromDB = useRef<string[]>([])

    const annotationUpdateListenerCallback = async (
        annotations: Core.Annotations.Annotation[],
        action: AnnotationUpdateType,
        { imported },
    ) => {
        const selectedExtractedValSnapId = lastSelectedSnapId;
        const selectedAnnotationId = lastSelectedAnnotationId;
        
        const annotation = annotations[0]

        debouncedSetAnnotationsUpdatedTrigger();

        if (!annotation?.isReply() || imported || addedFromDB.current.includes(annotation.getCustomData(CustomDataKey.relatedSnapshotId))) return

        const isAnnotationAppliedFromDB = ananotationReplyQueryData.some(el => el.id === annotation.getCustomData(CustomDataKey.relatedSnapshotId))
        const isAlreadyhandled = createdAnnotations.current.includes(annotation.getCustomData(CustomDataKey.relatedSnapshotId)) && action === 'add'
        if (isAnnotationAppliedFromDB && isAlreadyhandled) return

        console.log('Reply: isAnnotationAppliedFromDB', isAnnotationAppliedFromDB, 'isAlreadyhandled', isAlreadyhandled)

        const newItem: Partial<AnnotationReply> = {
            companyId: authData.company.id,
            createdBy: authData.user.uid,
            reportId: docId as string,
            inputFileId: tab as string,

            replyText: annotation.getContents(),
        }

        if (selectedAnnotationId) {
            newItem.parentAnnotationId = selectedAnnotationId
        } else if (selectedExtractedValSnapId) {
            newItem.parentSnapId = selectedExtractedValSnapId
        } else {
            console.error('Reply: No accociated id with reply')
        }

        if (action == 'add') {
            const relSnap = annotation.getCustomData(CustomDataKey.relatedSnapshotId)
            if(relSnap) {
                return
            }

            newItem.createdAt = new Date()

            const { id } = await annotationReplyCreateMutation.mutateAsync({
                data: newItem as AnnotationReply,
            })

            annotation.setCustomData(CustomDataKey.relatedSnapshotId, id)
            createdAnnotations.current.push(id)
        } else if (action == 'modify') {
            newItem.updatedAt = new Date()

            const replySnapId = annotation.getCustomData(CustomDataKey.relatedSnapshotId)

            if (!replySnapId) return

            await annotationReplyUpdateMutation.mutateAsync({
                id: replySnapId,
                data: newItem,
            })
            
            console.log('Reply: modified', newItem)
        } else if (action == 'delete') {
            newItem.deletedAt = new Date()

            const replySnapId = annotation.getCustomData(CustomDataKey.relatedSnapshotId)

            if (!replySnapId) return

            await annotationReplyUpdateMutation.mutateAsync({
                id: replySnapId,
                data: newItem,
            })
            deletedIds.current.push(annotation.getCustomData(CustomDataKey.relatedSnapshotId))
            
            console.log('Reply: Deleted', newItem)
        }
    }

    const { run: debouncedAnnotationListenerCallback } = useDebounceFn(
        annotationUpdateListenerCallback,
        { wait: 500 },
    );

    /**
     * Save reply
     */
    useEffect(() => {
        annotationManager?.addEventListener('annotationChanged', debouncedAnnotationListenerCallback);
    }, [annotationManager]);
    
    /**
     * Apply reply from DB
     * Only once for each element
     */
    useEffect(() => {
        if (!annotationManager || ananotationReplyQueryLoading || !pdfInstance) return

        let annotationList = annotationManager.getAnnotationsList()

        // TODO: Test sort
        const groupedByParent = groupAndSortReplies(
            ananotationReplyQueryData,
            parrentsApplied.current,
            [...createdAnnotations.current, ...deletedIds.current, ...annotationList.map(el => el.Id)],
        );

        console.log('Reply: To apply', groupedByParent)
        
        Object.entries(groupedByParent).map(([parentId, replies]) => {
            try {
                annotationList = annotationManager.getAnnotationsList()
                const parent = replies[0].parentAnnotationId
                    ? annotationManager.getAnnotationById(replies[0].parentAnnotationId)
                    : replies[0].parentSnapId ? getAnnotationBySnapId(annotationManager, replies[0].parentSnapId, annotationList) : null

                if (!parent) {
                    // console.error('Reply: Can\'t find parent annotation with id ', parentId)
                    return
                }

                console.log('Reply: Applied for parent', parentId)
                
                parrentsApplied.current.push(parentId)

                replies.map(reply => {
                    // addedFromDB.current.push(reply.replyAnnotationId)

                    const replyAnnot = annotationManager.createAnnotationReply(parent, reply.replyText);
             
                    replyAnnot.Author = authData.usersInCompany.find(el => el.uid === reply.createdBy)?.displayName || 'Unknown User';
                    replyAnnot.setCustomData(CustomDataKey.relatedSnapshotId, reply.id)

                    annotationManager.redrawAnnotation(replyAnnot);
                })
            } catch (e) {
                console.error('Reply: Can\'t apply for parent', parentId)
            }
        })
    }, [ananotationReplyQueryLastUpdatedAt, annotationManager, pdfInstance, annotationsUpdatedTrigger]);
}
