import React from 'react';

import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dropdown, Flex, MenuProps, Progress, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns/format';
import { updateDoc } from 'firebase/firestore';
import { generatePath, NavigateFunction } from 'react-router-dom';

import { InputFile } from '@/firestore/api/inputFiles.ts';
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate.ts';
import { fileTypeLabels } from '@/widgets/FileTabs';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';

import { FORM_ITEMS, Period } from './AddNewReportModal';
import { ReportReviewTableItem } from './FilesPage.types.ts';
import { DATE_FORMAT_DAY, DATE_FORMAT_MINUTE } from '../../constants/dates.ts';
import { ROUTES_CONFIG } from '../../constants/routes.ts';
import {
    ReportReview,
    reportProcessingStatusColor,
    reportProcessingStatusName,
    ReportType,
    reportTypeTitles,
    reportReviewStatusColor,
    reportReviewStatusName,
    reportReviewFInFrameworkTitles,
    ReportReviewFinFramework,
} from '../../firestore/api/reportReview.ts';

const PERIOD_NAME: Record<Period, string> = {
    quarterly: 'Quarterly',
    annual: 'Annual',
    monthly: 'Monthly',
    semiAnnual: 'Semi-Annual',
}

export const columns = (navigate: NavigateFunction, inputFiles: InputFile[]): ColumnsType<ReportReviewTableItem> => ([
    {
        width: 60,
        ellipsis: true,
        render: (_, item) => {
            return (
                // Fixme: Use compeleted steps for cals
                <Progress
                    type='circle'
                    percent={Math.ceil(item.progress)}
                    showInfo={false}
                    trailColor='#e6f4ff'
                    strokeWidth={20}
                    size={24}
                />
            )
        },
    },
    {
        title: 'Uploaded At',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        width: 170,
        render: (_, item) => (
            item.uploadedAt ? format(item.uploadedAt.toDate(), DATE_FORMAT_MINUTE) : '—'
        ),
    },
    {
        title: FORM_ITEMS.name.label,
        dataIndex: FORM_ITEMS.name.name,
        key: 'name',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'Files Uploaded',
        dataIndex: 'filesUploaded', 
        width: 150,
        ellipsis: false,
        render: (_, item) => {
            const filesByProcess = inputFiles.filter(el => el.reportId === item.docRef?.id)

            return (
                <Flex gap={4} wrap='wrap'>
                    {filesByProcess.map((file) => (
                        <Tag key={fileTypeLabels[file.fileType] || file.fileName} style={{ margin: 0 }}>
                            {fileTypeLabels[file.fileType] || file.fileName}
                        </Tag>
                    ))}
                </Flex>
            )
        },
    },
    {
        title: 'Review Status',
        dataIndex: 'reviewStatus',
        key: 'reviewStatus',
        render: (data, item: ReportReview) => (
            <Tag color={reportReviewStatusColor[item.reviewStatus]}>
                {reportReviewStatusName[item.reviewStatus]}
            </Tag>
        ),
        width: 200,
    },
    {
        title: 'Processing Status',
        dataIndex: 'status',
        key: 'status',
        render: (data, item: ReportReview) => (
            <Flex>
                <Tag color={reportProcessingStatusColor[item.status] || 'processing'}>
                    {reportProcessingStatusName[item.status] || firstLetterToUpperCase(item.status)}
                </Tag>
                {/*{item.errorMessages?.length > 0 && (*/}
                {/*    <Tooltip title={item.errorMessages.join('\n')}>*/}
                {/*        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>*/}
                {/*    </Tooltip>*/}
                {/*)}*/}
                {(item.errorMessages?.length > 0 && item.status === 'error') && (
                    <Tooltip title="Some automatic steps can't be fulfilled">
                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>
                    </Tooltip>
                )}
            </Flex>
        ),
        width: 200,
    },
    {
        title: FORM_ITEMS.reportType.label,
        dataIndex: FORM_ITEMS.reportType.name,
        key: FORM_ITEMS.reportType.name,
        width: 150,
        render: (value: ReportType) => (
            reportTypeTitles[value] ?? '—'
        ),
    },
    {
        title: 'FRF',
        dataIndex: FORM_ITEMS.finFramework.name,
        key: FORM_ITEMS.finFramework.name,
        width: 150,
        render: (value: ReportReviewFinFramework) => (
            reportReviewFInFrameworkTitles[value] ?? '—'
        ),
    },
    {
        title: FORM_ITEMS.reportDate.label,
        dataIndex: FORM_ITEMS.reportDate.name,
        key: FORM_ITEMS.reportDate.name,
        width: 150,
        render: (data: string) => {
            const d = data.toDate ? data.toDate() : data
        
            return format( normalizeShortBackDate(d), DATE_FORMAT_DAY)
        },
    },
    {
        title: FORM_ITEMS.period.label,
        dataIndex: FORM_ITEMS.period.name,
        key: FORM_ITEMS.period.name,
        width: 150,
        render: (value: Period) => (
            PERIOD_NAME[value]
        ),
    },
    {
        key: 'company',
        title: 'Client',
        dataIndex: ['company', 'name'],
        width: 200,
    },
    {
        // Data Transformed
        key: 'entity',
        title: 'Entity',
        dataIndex: ['entity', 'name'],
        width: 200,
    },
    {
        title: FORM_ITEMS.reviewer.label,
        key: FORM_ITEMS.reviewer.name,
        dataIndex: ['reviewerData', 'displayName'],
        width: 150,
    },
    {
        title: FORM_ITEMS.preparer.label,
        key: FORM_ITEMS.preparer.name,
        dataIndex: ['preparerData', 'displayName'],
        width: 150,
    },
    {
        title: FORM_ITEMS.draftNumber.label,
        key: FORM_ITEMS.draftNumber.name,
        dataIndex: FORM_ITEMS.draftNumber.name,
        width: 100,
    },
    {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 160,
        render: (_, item) => {
            const items: MenuProps['items'] = [
                // {
                //     key: '1',
                //     label: (
                //         <Typography.Link
                //             onClick={() => {
                //                 firebaseDownloadUrl(item.reportFile).then((url) =>{
                //                     downloadFile(url, item.name)
                //                 }).catch(() => {
                //                     message.error('Can\'t download the file')
                //                 })
                //             }}
                //         >
                //     Download Report
                //         </Typography.Link>
                //     ),
                // },
                {
                    key: '2',
                    label: (
                        <Typography.Link
                            onClick={() => {
                                item?.navigate(generatePath(ROUTES_CONFIG.REVIEW_EDIT.path, { id: item.docRef?.id }))
                            }}
                        >
                            Edit
                        </Typography.Link>
                    ),
                    disabled: item.archived,
                },
                (!item.archived ? ({
                    key: '3',
                    label: (
                        <Typography.Link
                            onClick={async () => {
                                if(!item.docRef) return;
                                await updateDoc(item.docRef, { archived: true })
                            }}
                        >
                    Archive
                        </Typography.Link>
                    ),
                }) : null),
            ].filter(Boolean);

            const disabled = (item.status !== 'done' && item.status !== 'error')
            
            return (
                <Tooltip
                    title={
                        item.archived ? 'This report is archived' : 
                            disabled ? 'The report validation is underway and should be ready for review in about 5 minutes' : 
                                undefined
                    }
                >
                    <Dropdown.Button
                        disabled={disabled}
                        icon={<DownOutlined/>}
                        menu={{ items }}
                        placement='bottomRight'
                        onClick={async () => {
                            if (item.archived) {
                                if (!item.docRef) return;
                                await updateDoc(item.docRef, { archived: false });
                            } else {
                                navigate(generatePath(ROUTES_CONFIG.REVIEW_REPORT.path, { id: item.docRef?.id }));
                            }
                        }}
                        type='primary'
                    >
                        {item.archived ? 'Unarchive' : 'Open'}
                    </Dropdown.Button>
                </Tooltip>
            )
        },
    },
]);
