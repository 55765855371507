import { useEffect, useState } from 'react'

import { LinkOutlined } from '@ant-design/icons';
import { Core } from '@pdftron/webviewer';
import { Flex, Typography } from 'antd';
import { query, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { reportPageClassificationRef } from '@/firestore/api/reportPageClassification.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { ACTIVE_IC_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';
import { CustomToolNames } from '@/widgets/PdfViewer2';

import { MoneyValuesNavigatorProps } from './MoneyValuesNavigator.types'

interface MoneyValuesNavigatorItems {
    value: string
    coordinates: [number, number, number, number]
}

/**
 * @deprecated Removed feature
 */
export const MoneyValuesNavigator = (props: MoneyValuesNavigatorProps) => {
    const [activeMoneyValues, setActiveMoneyValues] = useQueryParam(ACTIVE_IC_VALUE_QUERY_PARAM.name, ACTIVE_IC_VALUE_QUERY_PARAM.type)
    const { annotationManager, documentViewer } = useViewerDocument()
    const { id: docId } = useParams();

    const [activeListItem, setActiveListItem] = useState<number>();

    const [itemsList, setItemsList] = useState<Record<string, Core.Annotations.Annotation[]>>({});

    const [classification, classificationLoading] = useCollection(query(reportPageClassificationRef, where('reportId', '==', docId)))

    // Reset on initial load
    useEffect(() => {
        setActiveMoneyValues([])
    }, []);

    useEffect(() => {
        if (!annotationManager) return

        const moneyValues = annotationManager.getAnnotationsList().filter(annotation => {
            const toolName = annotation.getCustomData(CustomDataKey.toolName)
            const value = annotation.getCustomData(CustomDataKey.normalizedValue)

            return toolName === CustomToolNames.CrossLink &&
                   activeMoneyValues?.some(activeValue => activeValue === value)
        })

        const groupedByPage = moneyValues.sort((a, b) => a.getPageNumber() - b.getPageNumber()).reduce((acc, annotation) => {
            const pageNumber = annotation.getPageNumber()
            if (!acc[pageNumber]) {
                acc[pageNumber] = []
            }

            acc[pageNumber].push(annotation)

            return acc
        }, {})

        setItemsList(groupedByPage)
    }, [activeMoneyValues, annotationManager]);

    const anchorItems = Object.entries(itemsList)

    const [lastAnnotInd, setLastAnnotInd] = useState(0)

    if (!anchorItems.length) return null

    return (
        <Flex
            vertical
            gap={6}
            style={{
                padding: '6px 16px 6px 16px',
                background: 'white',
                borderRadius: 8,
                overflowY: 'auto',
                maxHeight: 300,
            }}
        >
            <Typography.Text strong>
                <LinkOutlined/>  Values matched
            </Typography.Text>
            {anchorItems.map(([page, annotations]) => {
                const pageClassificationLabel = classification?.docs[0]?.data()?.labels.find((label: any) => (label.pageIndex + 1) === Number(page))

                const title = pageClassificationLabel?.pageLabel ? `${firstLetterToUpperCase(pageClassificationLabel?.pageLabel.split('_').join(' ') )} - Page ${page + 1}  (${annotations.length})` : `Page ${page} (${annotations.length})`

                return (
                    <Flex
                        key={page}
                        vertical
                    >
                        <Typography.Link
                            style={{ userSelect: 'none' }}
                            onClick={() => {
                                const pageNumber = annotations[0].getPageNumber()
                                if(pageNumber === activeListItem) {
                                // Take nexly annot if exists
                                    const newInd = annotations[lastAnnotInd + 1] ? lastAnnotInd + 1 : 0
                                    annotationManager?.jumpToAnnotation(annotations[newInd])
                                    setLastAnnotInd(newInd)
                                } else {
                                    setLastAnnotInd(0)
                                    setActiveListItem(pageNumber)
                                    annotationManager?.jumpToAnnotation(annotations[0])
                                }
                            }}
                        >{title}</Typography.Link>
                    </Flex>
                )
            })}
        </Flex>
    )
}
