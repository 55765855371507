import { useEffect, useState } from 'react';

import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';

export let lastSelectedSnapId: string | undefined = undefined
export let lastSelectedAnnotationId: string | undefined = undefined

export const useFocusedValueId = () => {
    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(
        QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type,
    );
    
    const [,setRerenderTrigger] = useState(false)

    const [focusedAnnotationId] = useQueryParam(
        QUERY_PARAMS_CONFIG.FOCUSED_ANNOTATION_QUERY_PARAM.key, QUERY_PARAMS_CONFIG.FOCUSED_ANNOTATION_QUERY_PARAM.type,
    );
    
    const setSelectedExtractedValSnapId = (id: string | undefined) => {
        setFocusedSnapIdParam(id);
        lastSelectedSnapId = id
    }

    useEffect(() => {
        if(focusedSnapIdParam) {
            lastSelectedSnapId = focusedSnapIdParam
            lastSelectedAnnotationId = undefined
        } else if (focusedAnnotationId) {
            lastSelectedAnnotationId = focusedAnnotationId
            lastSelectedSnapId = undefined
        }
        setRerenderTrigger(prev => !prev)
    }, [focusedSnapIdParam, focusedAnnotationId]);

    const selectedExtractedValSnapId = lastSelectedSnapId;
    const selectedAnnotationId = lastSelectedAnnotationId;

    return {
        selectedExtractedValSnapId: selectedExtractedValSnapId,
        selectedAnnotationId: selectedAnnotationId,
        setSelectedExtractedValSnapId,
    };
}; 
