import { ReviewStep, ReviewStepTree } from './steps.types';

export type RepotPageBlockLabel = 'cover_page:entity_name' | 'cover_page:legal_form' | 'cover_page:date' | 'cover_page:rcs_number' | 'cover_page:registered_adress'

export type CC_ReportPageLabel = 'cover_page' | 'ecdf' | 'notes'

export const CC_RREPORT_PAGE_LABEL_TITLE: Record<CC_ReportPageLabel, string> = {
    cover_page: 'Cover Page',
    ecdf: 'ECDF',
    notes: 'Notes',
}

export type ComercialCompanyStepKeys =
    'COVER' |
    'COVER:ENTITY_NAME' |
    'COVER:LEGAL_FORM' |
    'COVER:RCS_NUMBER' |
    'COVER:DATE' |
    'COVER:REGISTERED_ADDRESS' |
    'TOC' |
    'TOC:VERIFICATION_OF_TABLE_OF_CONTENT_PAGE_NUMBERING' |
    'ECDF' |
    'ECDF:PREVIOUS_YEAR_COLUMN_BALANCES_CHECK' |
    'ECDF:INTERNAL_VALIDATION_OF_BALANCES' |
    'ECDF:MAPPING'|
    'ECDF:COMPARISON_OF_ECDF_BALANCES_WITH_NOTES' |
    'ECDF:RCS_NUMBER_CHECK' |
    'NOTES' |
    'NOTES:GENERAL_INFORMATION' |
    'NOTES:ACCOUNTING_POLICIES' |
    'NOTES:PREVIOUS_YEAR_COLUMN_BALANCES_CHECK' |
    'NOTES:FORMATION_EXPENSES' |
    'NOTES:INTANGIBLE_ASSETS' |
    'NOTES:TANGIBLE_ASSETS' |
    'NOTES:FINANCIAL_ASSETS' |
    'NOTES:CURRENT_ASSETS' |
    'NOTES:CAPITAL_AND_RESERVES' |
    'NOTES:CREDITORS' |
    'NOTES:OFF_BALANCE_SHEET' |
    'NOTES:POST_BALANCE_SHEET' |
    'ENTIRE_REPORT' |
    'ENTIRE_REPORT:DATE_CONSISTENCY' |
    'ENTIRE_REPORT:PBSE' |
    'ENTIRE_REPORT:PERIOD_YEAR'

export type ComercialCompanyReviewStep = ReviewStep<ComercialCompanyStepKeys>
export type ComercialCompanyReviewStepTree = ReviewStepTree<ComercialCompanyStepKeys>
