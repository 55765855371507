import { collection } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export const TABLE_OF_CONTENT_COLLECTION = 'tableOfContent'

export const tableOfContentRef = collection(db, TABLE_OF_CONTENT_COLLECTION)

export interface TableOfContent {
    id: string;
    companyId: string;
    content: string;
    coordinates: {
        height: number;
        width: number;
        x0: number;
        y0: number;
    };
    entityId: string;
    level: number;
    pageIndex: number;
    reportId: string;
}

export const {
    useFBItemsQuery: useTableOfContentQuery,
} = getRequestQueries<TableOfContent, {}>({
    url: TABLE_OF_CONTENT_COLLECTION,
})
