import { useContext, useEffect, useMemo, useState } from 'react'

import { ThunderboltFilled } from '@ant-design/icons';
import { QueryDocumentSnapshot } from '@firebase/firestore-types'
import * as Sentry from '@sentry/react';
import { Alert, Button, Divider, Flex, Spin, Tooltip, Typography } from 'antd';
import { and, doc, query, where, or } from 'firebase/firestore';
import { useCollection, useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { ReviewIdentifiedBlock, reviewIdentifiedBlockRef } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { StepSubchecks } from '@/pages/ReviewPage';
import { ExtractedValuesBlock } from '@/pages/ReviewPage/GuidePanel/Steps/StepContent/ExtractedValuesBlock';
import { getStepsByType } from '@/utils/getStepsByType.ts';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';
import { CommentsLibrary } from '@/widgets/CommentsLibrary';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';

import { StepContentProps } from './StepContent.types'
import { StepMessageItem } from './StepMessageItem';
import { SuggestedTools } from './SuggestedTools';
import { ReportReview } from '../../../../../firestore/api/reportReview.ts';
import { ResolvedComment, resolvedCommentRef } from '../../../../../firestore/api/resolvedComments.ts';
import { ReviewStepComment, reviewStepCommentRef } from '../../../../../firestore/api/reviewStepComment.ts';
import { reviewStepValidationRef } from '../../../../../firestore/api/reviewStepValidation.ts';
import { db } from '../../../../../firestore/config.ts';

export const StepContent = Sentry.withProfiler((props: StepContentProps) => {
    const { stepConfig, onNextStep, onSkipStep } = props

    const authData = useContext<AuthData>(AuthDataContext)
    
    const { annotationManager } = useViewerDocument()

    const { id: docId } = useParams()
    const [isCommentLibraryOpen, setIsCommentLibraryOpen] = useState(false)

    const [reportSnapshot, reportItemLoading ] = useDocument<ReportReview>(doc(db, 'reportReviews', docId))
    const reportData = reportSnapshot?.data()
    const reportType = reportData?.reportType

    const [tab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);

    const [reviewIdentifiedBlockSnapshot, reviewIdentifiedBlockLoading] = useCollection<ReviewIdentifiedBlock>(
        query(
            query(reviewIdentifiedBlockRef),
            and(
                where('companyId', '==' , authData.company.id),
                where('reportId', '==' , docId),
                where('rejected', '==' , false),
                or(
                // Null here is a hack to keep arr non empty
                    where('pageLabel', 'in', stepConfig?.annotationBlockIds || [null]),
                    where('stepKey', '==', stepConfig?.key),
                ),
            )),
    )

    const sortedBlockSnapshots = reviewIdentifiedBlockSnapshot?.docs.sort((a, b) => {
        const aData = a.data()
        const bData = b.data()

        return (bData.createdAt?.toDate?.().getTime() || 0) - (aData.createdAt?.toDate?.().getTime() || 0)
    })

    const stepsConfig = useMemo(() => getStepsByType(reportType), [reportType])

    const defaultFirstStep = stepsConfig?.find((step) => !!step.parentKey)?.key

    // FIXME: Stop Using templates and use text directly from config
    const [reviewStepValidationData, reviewStepValidationLoading] = useCollectionData(
        query(reviewStepValidationRef, where('reportOnReviewId', '==', docId)),
    )

    const [reviewStepCommensSnapshot, reviewStepCommentLoading] = useCollection(
        query(reviewStepCommentRef, where('reportOnReviewId', '==', docId)),
    )

    const [resolvedCommentsData, resolvedCommentsLoading] = useCollectionData(
        query(resolvedCommentRef, where('reportOnReviewId', '==', docId)),
    )

    const currentStepValidationData = reviewStepValidationData?.find((stepValidation) => stepValidation.step === stepConfig.key)

    const reviewStepComments = reviewStepCommensSnapshot?.docs
        ?.filter((comment: QueryDocumentSnapshot<ReviewStepComment>) => (
            ( comment.data().step === stepConfig.key) &&
            // Haven't beet resolved yet
            (!resolvedCommentsData?.some((el: ResolvedComment) => el.commentId === comment.id))
        ))

    const unlabeledComments = reviewStepComments?.filter((comment) => (
        // true if no same label in reviewIdentifiedBlockSnapshot
        !reviewIdentifiedBlockSnapshot?.docs.some((block) => block.data().label === comment.data().label)
    ))

    const handleCommentsLibraryOpen = () => {
        setIsCommentLibraryOpen(true)
    }

    const currentStepKey = reportSnapshot?.data()?.currentStep

    const isStepActive = (
        // Step is set
        (stepConfig.key === currentStepKey) ||
        // Or it's not and it's a first step
        (!reportItemLoading && !currentStepKey && stepConfig.key === defaultFirstStep)
    )

    if(!isStepActive) {
        return null
    }

    // Filter out comments without suggested messages
    const filteredSuggestedMessagesData = reviewStepComments?.map(el => el.data())?.filter((el: ReviewStepComment) => el.suggestedMessage?.length || el.explanation?.length)
    const hasErrors = Boolean(filteredSuggestedMessagesData?.length)

    if(resolvedCommentsLoading) {
        return (
            <Flex align='center' justify='center' style={{ padding: 8 }}>
                <Spin size='small'/>
            </Flex>
        )
    }

    const stepAutomationSuccessful = currentStepValidationData?.status === 'success' && !reviewStepValidationLoading
    const hasAnyNonGreenIdentifiedBlocks = sortedBlockSnapshots?.some((block) => (block.data().autoConfidence?.length && block.data().autoConfidence !== 'valid'))

    const messagesForCurrentStep = reviewStepCommensSnapshot?.docs?.filter(el => el.data().step === currentStepKey)

    //   use reviewStepCommensSnapshot?.docs here becuase need to see unresolved comments
    const noProblemsInStepFound = Boolean(
        !reviewIdentifiedBlockLoading
        && !messagesForCurrentStep?.length
        && !hasAnyNonGreenIdentifiedBlocks
        && stepAutomationSuccessful,
    )
    
    return (
        <Flex
            vertical
            style={{
                padding: '9px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px',
                marginTop: 12,
                marginRight: 4,
                marginLeft: 0,
            }}
        >
            <Flex gap={20} vertical>
                {noProblemsInStepFound && (
                    <Alert
                        message='No discrepancies were detected at this step'
                        type='success'
                        showIcon
                    />
                )}
                {Boolean(stepConfig.stepExplanationTemplates?.length) && (
                    <Flex vertical>
                        {/*<Typography.Text strong>Step Explanation</Typography.Text>*/}
                        {stepConfig.stepExplanationTemplates?.map((explanation: string) => (
                            <Typography.Paragraph key={explanation}>{explanation}</Typography.Paragraph>
                        ))}
                    </Flex>
                )}

                <StepSubchecks
                    stepConfig={stepConfig}
                    reportSnap={reportSnapshot}
                />

                <LocalErrorBoundary noContent>
                    <ExtractedValuesBlock
                        messages={reviewStepComments}
                        stepConfig={stepConfig}
                    />
                </LocalErrorBoundary>

                {(unlabeledComments?.length) ? (
                    <>
                        {/*TODO: Hint*/}
                        <Flex gap={4}>
                            <Tooltip placement='left' title='Items generated automatically for this step'><ThunderboltFilled style={{ color: '#13C2C2' }}/></Tooltip>
                            <Typography.Text strong>Suggested Comments</Typography.Text>
                        </Flex>
                        <Flex vertical gap={16}>
                            {unlabeledComments
                                ?.map((item) => (
                                    <LocalErrorBoundary 
                                        key={item.id}
                                        noContent
                                    >
                                        <StepMessageItem
                                            data={item.data() as ReviewStepComment}
                                            id={item.id}
                                        />
                                    </LocalErrorBoundary>
                                ))}
                        </Flex>
                    </>
                ) : null}

                {((!reviewIdentifiedBlockLoading && !sortedBlockSnapshots?.length) && (!hasErrors)) ? <SuggestedTools stepConfig={stepConfig}/> : null}

                {Boolean(Object.keys(stepConfig.suggestedMessageTemplates || {}).length) && (<Button onClick={handleCommentsLibraryOpen}>Comments Library</Button>)}
            </Flex>
            <Flex vertical>
                <Divider style={{ margin: '12px 0px' }}/>
                <Flex justify='space-between'>
                    <Button 
                        type='text'
                        style={{ color: '#bdb4b4' }} 
                        onClick={onSkipStep}
                    >
                        Not Applicable
                    </Button>
                    <Button
                        onClick={onNextStep}
                        type='primary'
                        // disabled={hasErrors}
                    >
                        Confirm Validation</Button>
                </Flex>
            </Flex>
            {isCommentLibraryOpen && (<CommentsLibrary stepConfig={stepConfig} onClose={() => setIsCommentLibraryOpen(false)}/>)}
        </Flex>
    )
})
