import { useEffect } from 'react';

import { useUpdateEffect } from 'ahooks';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

export const useCurrentPage = () => {
    const { documentViewer } = useViewerDocument();
    const [queryPage, setQueryPage] = useQueryParam(
        QUERY_PARAMS_CONFIG.PAGE.key, 
        QUERY_PARAMS_CONFIG.PAGE.type,
    );

    // Apply initial page from param
    useUpdateEffect(() => {
        if(!documentViewer || !queryPage) return;
        documentViewer.setCurrentPage(queryPage, false)
    }, [documentViewer]);
    
    useEffect(() => {
        if (!documentViewer) return;

        // Set initial page to default if query parameter is undefined
        if (queryPage === undefined) {
            setQueryPage(1);
        }

        const handlePageNumberUpdated = (pageNumber: number) => {
            setQueryPage(pageNumber);
        };

        documentViewer.addEventListener('pageNumberUpdated', handlePageNumberUpdated);

        return () => {
            documentViewer.removeEventListener('pageNumberUpdated', handlePageNumberUpdated);
        };
    }, [documentViewer, queryPage, setQueryPage]);

    return queryPage;
};
