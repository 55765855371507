import { FundReviewStep, FundReviewStepTree } from './fundSteps.types';

export const FUND_REVIEW_STEPS: FundReviewStep[] = [
    {
        key: 'COVER',
        title: 'Cover',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'COVER',
        'key': 'COVER:VERIFICATION_OF_THE_ENTITY_NAME',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the entity name',
        'suggestedMessageTemplates': {
            '1_COVER_A_N_FDB1': {
                'explanationTemplate': 'The entity name not found in both RCS document and the cover page. Please review whether the correct entity name is displayed on the cover page.',
                'suggestedMessageTemplate': 'The entity name not found in both RCS document and the cover page. Please review whether the correct entity name is displayed on the cover page.',
            },
            '1_COVER_A_N_FDB2': {
                'explanationTemplate': 'The entity name is not found on the cover page. Please review whether the correct entity name is displayed on the cover page.',
                'suggestedMessageTemplate': 'The entity name is not found on the cover page. Please review whether the correct entity name is displayed on the cover page.',
            },
            '1_COVER_A_N_FDB3': {
                'explanationTemplate': 'The entity name is not found in the RCS document. Please review whether the correct entity name is displayed on the cover page.',
                'suggestedMessageTemplate': 'The entity name is not found in the RCS document. Please review whether the correct entity name is displayed on the cover page.',
            },
            '1_COVER_A_N_FDB4': {
                'explanationTemplate': 'The entity name on the cover page matches the one in the RCS document',
                'suggestedMessageTemplate': 'The entity name on the cover page matches the one in the RCS document',
            },
            '1_COVER_A_N_FDB5': {
                'explanationTemplate': 'The entity name on the cover page does not match the RCS document. The entity name according to the RCS document: {{rcs_entity_name}}. The entity name according to the cover page: {{cover_entity_name}}. Please update it accordingly.',
                'suggestedMessageTemplate': 'The entity name on the cover page does not match the RCS document. The entity name according to the RCS document: {{rcs_entity_name}}. The entity name according to the cover page: {{cover_entity_name}}. Please update it accordingly.',
            },
            '1_COVER_A_N_FDB6': {
                'explanationTemplate': 'Formatting discrepancies detected in the entity name. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the entity name. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
            },
        },
    },
    {
        'parentKey': 'COVER',
        'key': 'COVER:VERIFICATION_OF_THE_LEGAL_FORM',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the legal form',
        'suggestedMessageTemplates': {
            '2_COVER_A_N_FDB1': {
                'explanationTemplate': 'The legal form not found in both RCS document and the cover page. Please review whether the correct legal form is displayed on the cover page.',
                'suggestedMessageTemplate': 'The legal form not found in both RCS document and the cover page. Please review whether the correct legal form is displayed on the cover page.',
            },
            '2_COVER_A_N_FDB2': {
                'explanationTemplate': 'The legal form is not found on the cover page. Please review whether the correct legal form is displayed on the cover page.',
                'suggestedMessageTemplate': 'The legal form is not found on the cover page. Please review whether the correct legal form is displayed on the cover page.',
            },
            '2_COVER_A_N_FDB3': {
                'explanationTemplate': 'The legal form is not found in the RCS document. Please review whether the correct legal form is displayed on the cover page.',
                'suggestedMessageTemplate': 'The legal form is not found in the RCS document. Please review whether the correct legal form is displayed on the cover page.',
            },
            '2_COVER_A_N_FDB4': {
                'explanationTemplate': 'The legal form on the cover page matches the one in the RCS document.',
                'suggestedMessageTemplate': 'The legal form on the cover page matches the one in the RCS document.',
            },
            '2_COVER_A_N_FDB5': {
                'explanationTemplate': 'The legal form on the cover page does not match the RCS document. The legal form according to the RCS document: {{rcs_legal_form}}. The legal form according to the cover page: {{cover_legal_form}}. Please update it accordingly.',
                'suggestedMessageTemplate': 'The legal form on the cover page does not match the RCS document. The legal form according to the RCS document: {{rcs_legal_form}}. The legal form according to the cover page: {{cover_legal_form}}. Please update it accordingly.',
            },
            '2_COVER_A_N_FDB6': {
                'explanationTemplate': 'Formatting discrepancies detected in the legal form. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the legal form. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
            },
        },
    },
    {
        'parentKey': 'COVER',
        'key': 'COVER:VERIFICATION_OF_THE_RCS_NUMBER',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the RCS number',
        'suggestedMessageTemplates': {
            '3_COVER_A_N_FDB1': {
                'explanationTemplate': 'The RCS number not found in both RCS document and the cover page. Please review whether the correct RCS number is displayed on the cover page.',
                'suggestedMessageTemplate': 'The RCS number not found in both RCS document and the cover page. Please review whether the correct RCS number is displayed on the cover page.',
            },
            '3_COVER_A_N_FDB2': {
                'explanationTemplate': 'The RCS number is not found on the cover page. Please review whether the correct RCS number is displayed on the cover page.',
                'suggestedMessageTemplate': 'The RCS number is not found on the cover page. Please review whether the correct RCS number is displayed on the cover page.',
            },
            '3_COVER_A_N_FDB3': {
                'explanationTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed on the cover page.',
                'suggestedMessageTemplate': 'The RCS number is not found in the RCS document. Please review whether the correct RCS number is displayed on the cover page.',
            },
            '3_COVER_A_N_FDB4': {
                'explanationTemplate': 'The RCS number on the cover page matches the one in the RCS document.',
                'suggestedMessageTemplate': 'The RCS number on the cover page matches the one in the RCS document.',
            },
            '3_COVER_A_N_FDB5': {
                'explanationTemplate': 'The RCS number on the cover page does not match the RCS document. The RCS number according to the RCS document: {{rcs_number}}. The RCS number according to the cover page: {{cover_rcs_number}}. Please update it accordingly.',
                'suggestedMessageTemplate': 'The RCS number on the cover page does not match the RCS document. The RCS number according to the RCS document: {{rcs_number}}. The RCS number according to the cover page: {{cover_rcs_number}}. Please update it accordingly.',
            },
            '3_COVER_A_N_FDB6': {
                'explanationTemplate': 'Formatting discrepancies detected in the RCS number. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the RCS number. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
            },
        },
    },
    {
        'parentKey': 'COVER',
        'key': 'COVER:VERIFICATION_OF_THE_REGISTERED_ADDRESS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the registered address',
        'suggestedMessageTemplates': {
            '4_COVER_A_N_FDB1': {
                'explanationTemplate': 'The registered address not found in both RCS document and the cover page. Please review whether the correct registered address is displayed on the cover page.',
                'suggestedMessageTemplate': 'The registered address not found in both RCS document and the cover page. Please review whether the correct registered address is displayed on the cover page.',
            },
            '4_COVER_A_N_FDB2': {
                'explanationTemplate': 'The registered address is not found on the cover page. Please review whether the correct registered address is displayed on the cover page.',
                'suggestedMessageTemplate': 'The registered address is not found on the cover page. Please review whether the correct registered address is displayed on the cover page.',
            },
            '4_COVER_A_N_FDB3': {
                'explanationTemplate': 'The registered address is not found in the RCS document. Please review whether the correct registered address is displayed on the cover page.',
                'suggestedMessageTemplate': 'The registered address is not found in the RCS document. Please review whether the correct registered address is displayed on the cover page.',
            },
            '4_COVER_A_N_FDB4': {
                'explanationTemplate': 'The registered address on the cover page matches the one in the RCS document.',
                'suggestedMessageTemplate': 'The registered address on the cover page matches the one in the RCS document.',
            },
            '4_COVER_A_N_FDB5': {
                'explanationTemplate': 'The registered address on the cover page does not match the RCS document. The registered address according to the RCS document: {{rcs_registered_address}}. The registered address according to the cover page: {{cover_registered_address}}. Please update it accordingly.',
                'suggestedMessageTemplate': 'The registered address on the cover page does not match the RCS document. The registered address according to the RCS document: {{rcs_registered_address}}. The registered address according to the cover page: {{cover_registered_address}}. Please update it accordingly.',
            },
            '4_COVER_A_N_FDB6': {
                'explanationTemplate': 'Formatting discrepancies detected in the registered address. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
                'suggestedMessageTemplate': 'Formatting discrepancies detected in the registered address. Compare and rectify any differences in spacing, punctuation, and case between the cover page and the RCS document.',
            },
        },
    },
    {
        key: 'TOC',
        title: 'Table of content',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'TOC',
        'key': 'TOC:VERIFICATION_OF_TABLE_OF_CONTENT_PAGE_NUMBERING',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of table of content page numbering',
        'suggestedMessageTemplates': {
            '6_TOC_A_N_FDB1': {
                'explanationTemplate': 'The table of contents page numbering is incorrect for the header: {header_list}',
                'suggestedMessageTemplate': 'The table of contents page numbering is incorrect for the header: {header_list}',
            },
            '6_TOC_A_N_FDB2': {
                'explanationTemplate': 'The table of contents page numbering is correct.',
                'suggestedMessageTemplate': 'The table of contents page numbering is correct.',
            },
        },
    },
    {
        key: 'ORG',
        title: 'Organization',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'ORG',
        'key': 'ORG:VERIFICATION_THAT_THE_SERVICE_PROVIDERS_LIST_ALIGNS_WITH_THE_LATEST_PROSPECTUS',
        'stepExplanationTemplates': [
        ],
        'title': 'Verification that the service providers list aligns with the latest prospectus',
        'suggestedMessageTemplates': {
            '5_ORG_M_N_FDB1': {
                'explanationTemplate': 'Please ensure that service providers list align with the latest prospectus.',
                'suggestedMessageTemplate': 'Please ensure that service providers list align with the latest prospectus.',
            },
        },
    },
    {
        key: 'SNA',
        title: 'Statement of Net Assets',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:VERIFICATION_OF_THE_SUBTOTALS_IN_THE_STATEMENT_OF_NET_ASSETS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the subtotals in the Statement of Net Assets',
        'suggestedMessageTemplates': {
            '7_SNA_A_N_FDB1': {
                'explanationTemplate': 'Following subtotal(s) in the Statement of Net Assets are incorrect: {{sub_fund_sub_totals_dict}}',
                'suggestedMessageTemplate': 'Following subtotal(s) in the Statement of Net Assets are incorrect: {{sub_fund_sub_totals_dict}}',
            },
            '7_SNA_A_N_FDB2': {
                'explanationTemplate': 'Subtotals in the Statement of Net Assets are correct for all sub-funds.',
                'suggestedMessageTemplate': 'Subtotals in the Statement of Net Assets are correct for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:VERIFICATION_OF_WHETHER_TOTAL_ASSETS_MINUS_TOTAL_LIABILITIES_ARE_EQUAL_TO_NET_ASSETS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of whether total assets minus total liabilities are equal to net assets',
        'suggestedMessageTemplates': {
            '8_SNA_A_N_FDB1': {
                'explanationTemplate': 'Total assets minus total liabilities are not equal to net assets for the sub-fund(s): {{sub_fund_list}}',
                'suggestedMessageTemplate': 'Total assets minus total liabilities are not equal to net assets for the sub-fund(s): {{sub_fund_list}}',
            },
            '8_SNA_A_N_FDB2': {
                'explanationTemplate': 'Total assets minus total liabilities are not equal to net assets in the combined column',
                'suggestedMessageTemplate': 'Total assets minus total liabilities are not equal to net assets in the combined column',
            },
            '8_SNA_A_N_FDB3': {
                'explanationTemplate': 'Total assets minus total liabilities equal net assets for all sub-funds and combined column.',
                'suggestedMessageTemplate': 'Total assets minus total liabilities equal net assets for all sub-funds and combined column.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:VERIFICATION_OF_CONSISTENCY_OF_HISTORICAL_NET_ASSETS_WITH_PRIOR_PERIOD_FINANCIAL_STATEMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of consistency of historical net assets with prior period financial statements',
        'suggestedMessageTemplates': {
            '9_SNA_A_N_FDB1': {
                'explanationTemplate': 'Historical net assets are not consistent with prior period financial statements for the sub-fund(s): {{sub_fund_list}}',
                'suggestedMessageTemplate': 'Historical net assets are not consistent with prior period financial statements for the sub-fund(s): {{sub_fund_list}}',
            },
            '9_SNA_A_N_FDB2': {
                'explanationTemplate': 'Historical net assets are consistent with prior period financial statements for all sub-fund(s).',
                'suggestedMessageTemplate': 'Historical net assets are consistent with prior period financial statements for all sub-fund(s).',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:VERIFICATION_OF_NOTE_REFERENCES',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of note references',
        'suggestedMessageTemplates': {
            '10_SNA_A_N_FDB1': {
                'explanationTemplate': 'Note references are incorrect for the caption(s): {{caption_list}}.',
                'suggestedMessageTemplate': 'Note references are incorrect for the caption(s): {{caption_list}}.',
            },
            '10_SNA_A_N_FDB2': {
                'explanationTemplate': 'Note references are correct.',
                'suggestedMessageTemplate': 'Note references are correct.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_NET_ASSETS_BETWEEN_STATEMENT_OF_NET_ASSETS_AND_STATEMENT_OF_OPERATIONS_AND_CHANGES_IN_NET_ASSETS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of net assets between Statement of Net Assets and Statement of Operations and Changes in Net Assets',
        'suggestedMessageTemplates': {
            '11_SNA_A_N_FDB1': {
                'explanationTemplate': 'Net assets between the statements are not consistent for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Net assets between the statements are not consistent for the sub-fund(s): {{sub_fund_list}}.',
            },
            '11_SNA_A_N_FDB2': {
                'explanationTemplate': 'Net assets between the statements are consistent for all sub-funds.',
                'suggestedMessageTemplate': 'Net assets between the statements are consistent for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_SECURITIES_PORTFOLIO_AT_MARKET_VALUE_BALANCE_WITH_STATEMENT_OF_INVESTMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of Securities portfolio at market value balance with Statement of Investments',
        'suggestedMessageTemplates': {
            '12_SNA_A_N_FDB1': {
                'explanationTemplate': 'Securities portfolio at market value balance is not consistent with Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Securities portfolio at market value balance is not consistent with Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
            },
            '12_SNA_A_N_FDB2': {
                'explanationTemplate': 'Securities portfolio at market value balance is consistent with Statement of Investments for all sub-funds.',
                'suggestedMessageTemplate': 'Securities portfolio at market value balance is consistent with Statement of Investments for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_NET_ASSETS_BETWEEN_STATEMENT_OF_NET_ASSETS_AND_STATEMENT_OF_INVESTMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of net assets between Statement of Net Assets and Statement of Investments',
        'suggestedMessageTemplates': {
            '13_SNA_A_N_FDB1': {
                'explanationTemplate': 'Net assets are not consistent between Statement of Net Assets and Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Net assets are not consistent between Statement of Net Assets and Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
            },
            '13_SNA_A_N_FDB2': {
                'explanationTemplate': 'Net assets are consistent between Statement of Net Assets and Statement of Investments for all sub-funds.',
                'suggestedMessageTemplate': 'Net assets are consistent between Statement of Net Assets and Statement of Investments for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_CASH_AT_BANK_BALANCE_BETWEEN_STATEMENT_OF_NET_ASSETS_AND_STATEMENT_OF_INVESTMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of Cash at bank balance between Statement of Net Assets and Statement of Investments',
        'suggestedMessageTemplates': {
            '14_SNA_A_N_FDB1': {
                'explanationTemplate': 'Cash at bank balance is not consistent between Statement of Net Assets and Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Cash at bank balance is not consistent between Statement of Net Assets and Statement of Investments for the sub-fund(s): {{sub_fund_list}}.',
            },
            '14_SNA_A_N_FDB2': {
                'explanationTemplate': 'Cash at bank balance is consistent between Statement of Net Assets and Statement of Investments for all sub-funds.',
                'suggestedMessageTemplate': 'Cash at bank balance is consistent between Statement of Net Assets and Statement of Investments for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_BALANCES_IN_THE_STATEMENT_OF_NET_ASSETS_AND_DERIVATIVES_OUTSTANDING_DISCLOSED_IN_THE_NOTES_SECTION',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of balances in the Statement of Net Assets and derivatives outstanding, disclosed in the Notes section.',
        'suggestedMessageTemplates': {
            '15_SNA_A_N_FDB1': {
                'explanationTemplate': 'Balances in the Statement of Net Assets and derivatives outstanding are not consistent for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Balances in the Statement of Net Assets and derivatives outstanding are not consistent for the sub-fund(s): {{sub_fund_list}}.',
            },
            '15_SNA_A_N_FDB2': {
                'explanationTemplate': 'Balances in the Statement of Net Assets and derivatives outstanding are consistent for all sub-funds.',
                'suggestedMessageTemplate': 'Balances in the Statement of Net Assets and derivatives outstanding are consistent for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SNA',
        'key': 'SNA:CROSS-VERIFICATION_OF_COMBINED_NET_ASSETS_IN_THE_STATEMENT_OF_NET_ASSETS_AND_STATEMENT_OF_OPERATIONS_AND_CHANGES_IN_NET_ASSETS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of combined net assets in the Statement of Net Assets and Statement of Operations and Changes in Net Assets',
        'suggestedMessageTemplates': {
            '16_SNA_A_N_FDB1': {
                'explanationTemplate': 'Total combined net assets are not consistent between the statements.',
                'suggestedMessageTemplate': 'Total combined net assets are not consistent between the statements.',
            },
            '16_SNA_A_N_FDB2': {
                'explanationTemplate': 'Total combined net assets are consistent between the statements.',
                'suggestedMessageTemplate': 'Total combined net assets are consistent between the statements.',
            },
        },
    },
    {
        key: 'SOP',
        title: 'Statement of Operations and Changes in Net Assets',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'SOP',
        'key': 'SOP:VERIFICATION_OF_THE_SUBTOTALS_IN_THE_STATEMENT_OF_OPERATIONS_AND_CHANGES_IN_NET_ASSETS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the subtotals in the Statement of Operations and Changes in Net Assets',
        'suggestedMessageTemplates': {
            '17_SOP_A_N_FDB1': {
                'explanationTemplate': 'Following subtotal(s) in the Statement of Operations and Changes in Net Assets are incorrect: {{sub_fund_sub_totals_dict}}',
                'suggestedMessageTemplate': 'Following subtotal(s) in the Statement of Operations and Changes in Net Assets are incorrect: {{sub_fund_sub_totals_dict}}',
            },
            '17_SOP_A_N_FDB2': {
                'explanationTemplate': 'Subtotals in the Statement of Operations and Changes in Net Assets are correct for all sub-funds.',
                'suggestedMessageTemplate': 'Subtotals in the Statement of Operations and Changes in Net Assets are correct for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SOP',
        'key': 'SOP:VERIFICATION_OF_THE_OPENING_NET_ASSETS_WITH_PRIOR_YEAR_FINANCIAL_STATEMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the opening net assets with prior year financial statements.',
        'suggestedMessageTemplates': {
            '18_SOP_A_N_FDB1': {
                'explanationTemplate': 'Opening net assets are not consistent with prior year financial statements for the sub-fund(s): {{sub_fund_list}}.',
                'suggestedMessageTemplate': 'Opening net assets are not consistent with prior year financial statements for the sub-fund(s): {{sub_fund_list}}.',
            },
            '18_SOP_A_N_FDB2': {
                'explanationTemplate': 'Opening net assets are consistent with prior year financial statements.',
                'suggestedMessageTemplate': 'Opening net assets are consistent with prior year financial statements.',
            },
        },
    },
    {
        'parentKey': 'SOP',
        'key': 'SOP:VERIFICATION_OF_NOTE_REFERENCES',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of note references',
        'suggestedMessageTemplates': {
            '19_SOP_A_N_FDB1': {
                'explanationTemplate': 'Note references are incorrect for the caption(s): {{caption_list}}.',
                'suggestedMessageTemplate': 'Note references are incorrect for the caption(s): {{caption_list}}.',
            },
            '19_SOP_A_N_FDB2': {
                'explanationTemplate': 'Note references are correct.',
                'suggestedMessageTemplate': 'Note references are correct.',
            },
        },
    },
    {
        'parentKey': 'SOP',
        'key': 'SOP:CROSS-VERIFICATION_OF_BALANCES_IN_THE_STATEMENT_OF_OPERATION_AND_CHANGES_IN_NET_ASSETS_WITH_THE_NOTES_SECTION',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Cross-verification of balances in the Statement of Operation and Changes in Net Assets with the Notes section',
        'suggestedMessageTemplates': {
            '20_SOP_A_N_FDB1': {
                'explanationTemplate': 'Balances in the Statement of Operation and Changes in Net Assets are inconsistent with corresponding tables in the Notes section. There are discrepancies in the following captions: {{sub_fund_caption_dict}}.',
                'suggestedMessageTemplate': 'Balances in the Statement of Operation and Changes in Net Assets are inconsistent with corresponding tables in the Notes section. There are discrepancies in the following captions: {{sub_fund_caption_dict}}.',
            },
            '20_SOP_A_N_FDB2': {
                'explanationTemplate': 'Balances in the Statement of Operation and Changes in Net Assets are consistent with corresponding tables in the Notes section, for all sub-funds.',
                'suggestedMessageTemplate': 'Balances in the Statement of Operation and Changes in Net Assets are consistent with corresponding tables in the Notes section, for all sub-funds.',
            },
        },
    },
    {
        key: 'STATS',
        title: 'Statistical Section',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'STATS',
        'key': 'STATS:VERIFICATION_OF_CONSISTENCY_OF_HISTORICAL_NET_ASSET_VALUE_PER_SHARE_WITH_PRIOR_PERIOD_FINANCIAL_STATEMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of consistency of historical Net Asset Value per Share with prior period financial statements',
        'suggestedMessageTemplates': {
            '21_STATS_A_N_FDB1': {
                'explanationTemplate': 'Historical Net Asset Value per Share is not consistent with prior period financial statements. There are discrepancies on the following shareclass(es): {{statistical_nested_dict}}.',
                'suggestedMessageTemplate': 'Historical Net Asset Value per Share is not consistent with prior period financial statements. There are discrepancies on the following shareclass(es): {{statistical_nested_dict}}.',
            },
            '21_STATS_A_N_FDB2': {
                'explanationTemplate': 'Historical Net Asset Value per Share is consistent with prior period financial statements for all sub-funds.',
                'suggestedMessageTemplate': 'Historical Net Asset Value per Share is consistent with prior period financial statements for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'STATS',
        'key': 'STATS:VERIFICATION_OF_COMPLETENESS_OF_THE_SUB-FUNDS_IN_THE_STATISTICAL_SECTION',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of completeness of the sub-funds in the Statistical Section',
        'suggestedMessageTemplates': {
            '22_STATS_A_N_FDB1': {
                'explanationTemplate': 'There are missing sub-fund(s) in the Statistical section. \nFollowing sub-funds are presented in the Statement of Net Assets but not in the Statistical sections: {{sub-funds}}.\nFollowing sub-funds are presented in the Statement of Operations and Changes in the Net Assets but not in the Statistical sections:',
                'suggestedMessageTemplate': 'There are missing sub-fund(s) in the Statistical section. \nFollowing sub-funds are presented in the Statement of Net Assets but not in the Statistical sections: {{sub-funds}}.\nFollowing sub-funds are presented in the Statement of Operations and Changes in the Net Assets but not in the Statistical sections:',
            },
            '22_STATS_A_N_FDB2': {
                'explanationTemplate': 'All sub-funds mentioned in the Financial Statements are included in the the Statistical section.',
                'suggestedMessageTemplate': 'All sub-funds mentioned in the Financial Statements are included in the the Statistical section.',
            },
        },
    },
    {
        key: 'SOI',
        title: 'Statement of Investments',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        'parentKey': 'SOI',
        'key': 'SOI:VERIFICATION_OF_THE_SUBTOTALS_IN_THE_STATEMENT_OF_INVESTMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of the subtotals in the Statement of Investments',
        'suggestedMessageTemplates': {
            '23_SOP_A_N_FDB1': {
                'explanationTemplate': 'Following subtotal(s) in the Statement of Investments are incorrect: {{sub_fund_sub_totals_dict}}',
                'suggestedMessageTemplate': 'Following subtotal(s) in the Statement of Investments are incorrect: {{sub_fund_sub_totals_dict}}',
            },
            '23_SOP_A_N_FDB2': {
                'explanationTemplate': 'Subtotals in the Statement of Investments are correct for all sub-funds.',
                'suggestedMessageTemplate': 'Subtotals in the Statement of Investments are correct for all sub-funds.',
            },
        },
    },
    {
        'parentKey': 'SOI',
        'key': 'SOI:IDENTIFICATION_OF_MATURITY_DATES_BEFORE_THE_PERIOD_END_DATE',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Identification of maturity dates before the period end date',
        'suggestedMessageTemplates': {
            '24_SOI_A_N_FDB1': {
                'explanationTemplate': 'The following investments mature before the end of the financial period covered by this report: {{exp_inv_list}}',
                'suggestedMessageTemplate': 'The following investments mature before the end of the financial period covered by this report: {{exp_inv_list}}',
            },
        },
    },
    {
        'parentKey': 'SOI',
        'key': 'SOI:IDENTIFICATION_OF_SUB-FUND_CROSS-INVESTMENTS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Identification of sub-fund cross-investments',
        'suggestedMessageTemplates': {
            '25_SOI_A_Y_FDB1': {
                'explanationTemplate': 'Cross sub-fund investments have been identified, but relevant note disclosures are missing in the Notes section. Please consult our note library for guidance.',
                'suggestedMessageTemplate': 'Cross sub-fund investments have been identified, but relevant note disclosures are missing in the Notes section. Please consult our note library for guidance.',
            },
        },
    },
    {
        key: 'NOTES',
        title: 'Notes',
        stepExplanationTemplates: [],
        suggestedMessageTemplates: {},
    },
    {
        key: 'NOTES:GENERAL_INFORMATION',
        parentKey: 'NOTES',
        title: 'General information',
        stepExplanationTemplates: ['Does the general information note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:GENERAL_INFORMATION:COMPANY_NAME',
                'title': 'Fund name',
                'explanation': 'It is considered best practice to disclose the company name in the general information note. The name should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:LEGAL_FORM',
                'title': 'Legal form',
                'explanation': 'It is considered best practice to disclose the legal form in the general information note. The legal form name should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:REGISTERED_OFFICE',
                'title': 'Registered address',
                'explanation': 'It is considered best practice to disclose the registered address in the general information note. The registered address should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:TRADE_REGISTER_NUMBER',
                'title': 'RCS number',
                'explanation': 'It is considered best practice to disclose the RCS number in the general information note. The RCS number should align with the one in the RCS document.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:SUB_FUNDS_LIST',
                'title': 'List of active sub-funds',
                'explanation': 'It is considered best practice to disclose the list of active sub-funds available as of the date of the financial statements.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:SHARE_CLASS_LIST',
                'title': 'List of active share classes / units',
                'explanation': 'It is considered best practice to disclose the list of active sub-funds available as of the date of the financial statements.',
            },
            {
                'key': 'NOTES:GENERAL_INFORMATION:CY_EVENTS',
                'title': 'Significant events during the year / period',
                'explanation': 'It is considered best practice to disclose significant events of the year that impact the annual accounts, to improve understanding of the report.',
            },
        ],
    },
    {
        key: 'NOTES:ACCOUNTING_POLICIES',
        parentKey: 'NOTES',
        title: 'Significant accounting policies',
        stepExplanationTemplates: ['Does the accounting policy note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:ACCOUNTING_POLICIES:POLICIES',
                'title': 'Disclosure of significant accounting policies and valuation rules',
                'explanation': 'Disclosure of the accounting policies and valuation rules applied to the items in the annual accounts (including principles, bases, conventions, rules and specific practices)',
            },
            {
                'key': 'NOTES:ACCOUNTING_POLICIES:CURRENCY',
                'title': 'Foreign currency translation rates',
                'explanation': 'Mention of the currency in which the accounting records are maintained and the annual accounts are expressed.',
            },
        ],
    },
    {
        key: 'NOTES:MANAGEMENT_FEES',
        parentKey: 'NOTES',
        title: 'Management fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:MANAGEMENT_FEES_EFFECTIVE',
                'title': 'Disclosure of the effective management fees',
                'explanation': 'It is considered best practice to disclose the effective management fee rate applied during the year and not only a maximum amount.',
            },
            {
                'key': 'NOTES:MANAGEMENT_FEES_MAXIMUM',
                'title': 'Disclosure of the maximum management fees',
                'explanation': 'It is considered best practice to disclose the maximum management fees.',
            },
        ],
    },
    {
        key: 'NOTES:ADMIN_DOM_FEES',
        parentKey: 'NOTES',
        title: 'Administrative and domiciliary agent fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:ADMIN_DOM_FEES_PER',
                'title': 'Disclosure of percentage fee',
                'explanation': 'Ensure that the percentage fee disclosed in the note aligns with the latest prospectus of the Fund. Verify that the fee is accurately stated and reflects any recent changes or updates to the prospectus.',
            },
            {
                'key': 'NOTES:ADMIN_DOM_FEES_FREQ',
                'title': 'Disclosure of payment frequency',
                'explanation': 'Ensure that the payment frequency disclosed in the note matches the payment terms outlined in the latest prospectus.',
            },
        ],
    },
    {
        key: 'NOTES:INV_ADV',
        parentKey: 'NOTES',
        title: 'Investment management/advisory fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:INV_ADV_PER',
                'title': 'Disclosure of the percentage fee',
                'explanation': 'Ensure that the percentage fee disclosed in the note aligns with the latest prospectus of the Fund. Verify that the fee is accurately stated and reflects any recent changes or updates to the prospectus.',
            },
            {
                'key': 'NOTES:INV_ADV_FREQ',
                'title': 'Disclosure of the payment frequency',
                'explanation': 'Ensure that the payment frequency disclosed in the note matches the payment terms outlined in the latest prospectus.',
            },
        ],
    },
    {
        key: 'NOTES:PERF',
        parentKey: 'NOTES',
        title: 'Performance fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:PERF_MODEL',
                'title': 'Model description and basis of calculation',
                'explanation': 'Ensure the performance fee model is described in sufficient detail to allow readers to understand the basis of calculation. Confirm that the description aligns with the performance fee model outlined in the latest prospectus.',
            },
            {
                'key': 'NOTES:PERF_RATE',
                'title': 'Disclosure of the performance fee rate',
                'explanation': 'Ensure that the performance fee rates are aligned with the latest prospectus of the Fund.',
            },
            {
                'key': 'NOTES:PERF_BEN',
                'title': 'Disclosure of the benchmark',
                'explanation': 'Ensure that the benchmarks are aligned with the latest prospectus of the Fund.',
            },
            {
                'key': 'NOTES:PERF_CHARGED',
                'title': 'Disclosure of the performance fee charged',
                'explanation': 'Ensure that the amount of performance fee charged is aligned with statement of operations.',
            },
        ],
    },
    {
        key: 'NOTES:DEPO',
        parentKey: 'NOTES',
        title: 'Depositary bank fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:DEPO_PER',
                'title': 'Disclosure of the percentage fee',
                'explanation': 'Ensure that the percentage fee disclosed in the note aligns with the latest prospectus of the Fund. Verify that the fee is accurately stated and reflects any recent changes or updates to the prospectus.',
            },
            {
                'key': 'NOTES:DEPO_FREQ',
                'title': 'Disclosure of the payment frequency',
                'explanation': 'Ensure that the payment frequency disclosed in the note matches the payment terms outlined in the latest prospectus.',
            },
        ],
    },
    {
        key: 'NOTES:TA',
        parentKey: 'NOTES',
        title: 'Transfer agent fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:TA_PER',
                'title': 'Disclosure of the percentage fee',
                'explanation': 'Ensure that the percentage fee disclosed in the note aligns with the latest prospectus of the Fund. Verify that the fee is accurately stated and reflects any recent changes or updates to the prospectus.',
            },
            {
                'key': 'NOTES:TA_FREQ',
                'title': 'Disclosure of the payment frequency',
                'explanation': 'Ensure that the payment frequency disclosed in the note matches the payment terms outlined in the latest prospectus.',
            },
        ],
    },
    {
        key: 'NOTES:DIST',
        parentKey: 'NOTES',
        title: 'Distribution fees',
        stepExplanationTemplates: ['Does the note include the following details?'],
        suggestedMessageTemplates: {},
        subChecks: [
            {
                'key': 'NOTES:DIST_PER',
                'title': 'Disclosure of the percentage fee',
                'explanation': 'Ensure that the percentage fee disclosed in the note aligns with the latest prospectus of the Fund. Verify that the fee is accurately stated and reflects any recent changes or updates to the prospectus.',
            },
            {
                'key': 'NOTES:DIST_FREQ',
                'title': 'Disclosure of the payment frequency',
                'explanation': 'Ensure that the payment frequency disclosed in the note matches the payment terms outlined in the latest prospectus.',
            },
        ],
    },
    {
        'key': 'ENTIRE_REP',
        'stepExplanationTemplates': [],
        'title': 'Entire Report',
        'suggestedMessageTemplates': {},
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_IF_ON_EACH_PAGE_OF_THE_AUDITED_STATEMENTS_(I.E._STATEMENT_OF_NET_ASSETS_STATEMENT_OF_OPERATIONS_AND_CHANGES_IN_NET_ASSETS_STATEMENT_OF_INVESTMENTS)_THE_FOLLOWING_SENTENCE_IS_ADDED_AS_A_FOOTER:_\'THE_ACCOMPANYING_NOTES_FORM_AN_INTEGRAL_PART_OF_THESE_FINANCIAL_STATEMENTS\'',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification if on each page of the audited statements (i.e., Statement of Net Assets, Statement of Operations and Changes in Net Assets, Statement of Investments), the following sentence is added as a footer: \'The accompanying notes form an integral part of these financial statements\'',
        'suggestedMessageTemplates': {
            '29_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': 'The footer \'The accompanying notes form an integral part of these financial statements\' is not added on each page.',
                'suggestedMessageTemplate': 'The footer \'The accompanying notes form an integral part of these financial statements\' is not added on each page.',
            },
            '29_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': 'The footer \'The accompanying notes form an integral part of these financial statements\' is added on each page.',
                'suggestedMessageTemplate': 'The footer \'The accompanying notes form an integral part of these financial statements\' is added on each page.',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_OF_DATE_CONS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Date format consistency verification',
        'suggestedMessageTemplates': {
            '30_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': 'Date format as per cover page: {{date_readable_format}}, Nonconforming dates: {{nonconforming_dates}}.',
                'suggestedMessageTemplate': 'Date format as per cover page: {{date_readable_format}}, Nonconforming dates: {{nonconforming_dates}}.',
            },
            '30_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': 'Date format as per cover page: {{date_readable_format}}, Nonconforming dates: {{nonconforming_dates}}.',
                'suggestedMessageTemplate': 'Date format as per cover page: {{date_readable_format}}, Nonconforming dates: {{nonconforming_dates}}.',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_OF_SPELLING',
        'title': 'Verification of spelling and grammar across the report.',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            '31_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': 'TBA',
                'suggestedMessageTemplate': 'TBA',
            },
            '31_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': 'TBA',
                'suggestedMessageTemplate': 'TBA',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_OF_ENTITY_NAME_CONS',
        'title': 'Verification of consistency in the entity name spelling across the report',
        'stepExplanationTemplates': [],
        'suggestedMessageTemplates': {
            '32_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': 'TBA',
                'suggestedMessageTemplate': 'TBA',
            },
            '32_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': 'TBA',
                'suggestedMessageTemplate': 'TBA',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_OF_CURR_CONS',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of consistency of currency symbols (ISO 4217)',
        'suggestedMessageTemplates': {
            '33_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': '‘TBA',
                'suggestedMessageTemplate': '‘TBA',
            },
            '33_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': '‘TBA',
                'suggestedMessageTemplate': '‘TBA',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_OF_CONS_PERIOD_YEAR',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification of reference consistency: period or year',
        'suggestedMessageTemplates': {
            '34_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': '‘TBA',
                'suggestedMessageTemplate': '‘TBA',
            },
            '34_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': '‘TBA',
                'suggestedMessageTemplate': '‘TBA',
            },
        },
    },
    {
        'parentKey': 'ENTIRE_REP',
        'key': 'ENTIRE REP:VERIFICATION_WHETHER_SUBSEQUENT_EVENT_NOTE_HAS_BEEN_INCLUDED',
        'stepExplanationTemplates': [],
        'checkType': 'AUTOMATIC',
        'title': 'Verification whether ‘subsequent event note’ has been included',
        'suggestedMessageTemplates': {
            '35_ENTIRE_REP_A_N_FDB1': {
                'explanationTemplate': '‘Subsequent event note’ has not been included.',
                'suggestedMessageTemplate': '‘Subsequent event note’ has not been included.',
            },
            '35_ENTIRE_REP_A_N_FDB2': {
                'explanationTemplate': '‘Subsequent event note’ has been included.',
                'suggestedMessageTemplate': '‘Subsequent event note’ has been included.',
            },
        },
    },
]

export const FUND_REVIEW_STEPS_TREE: FundReviewStepTree[] = FUND_REVIEW_STEPS
    .filter(el => !el.parentKey)
    .map(el => ({ ...el, children: FUND_REVIEW_STEPS.filter(child => child.parentKey === el.key) }))
